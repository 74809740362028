import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { Position, Menu, Button, Popover } from 'evergreen-ui'
import { Stack, Box } from '@chakra-ui/react';

import { getRandomHexColor } from '../../Constants/ColorFunctions';
  

const Navbar = () => {
  return (
    <>
    <Stack direction={'row'} spacing={4} style={{
        padding: '15px',
        display: 'table',
        margin: 'auto'
        }}>
        <Box key={"tesy"}>
        <Button appearance="minimal">
            <NavLink to='/' activeStyle>
            Home
            </NavLink>
        </Button>
        <Popover
          position={Position.BOTTOM_LEFT}
          trigger='click'
          content={
            <Menu>
              <Menu.Group>
                <Menu.Item><Link reloadDocument to="/hex-to/rgb">HEX to RGB</Link></Menu.Item>
                <Menu.Item><Link reloadDocument to="/hex-to/cymk">HEX to CYMK</Link></Menu.Item>
                <Menu.Item><Link reloadDocument to="/hex-to/hsl">HEX to HSL</Link></Menu.Item>
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item><Link reloadDocument to="/rgb-to/hex">RGB to HEX</Link></Menu.Item>
                <Menu.Item><Link reloadDocument to="/rgb-to/hsl">RGB to HSL</Link></Menu.Item>
                <Menu.Item><Link reloadDocument to="/rgb-to/cymk">RGB to CYMK</Link></Menu.Item>
              </Menu.Group>
            </Menu>
          }>
            <Button marginRight={16} appearance="minimal" style={{color: 'grey'}}>Convert</Button>
          </Popover>
          <Popover
          position={Position.BOTTOM_LEFT}
          trigger='click'
          content={
            <Menu>
              <Menu.Group>
                <Menu.Item><Link reloadDocument to="/hex-to/uicolor">HEX to UIColor</Link></Menu.Item>
                <Menu.Item><Link reloadDocument to="/hex-to/java">HEX to Java</Link></Menu.Item>
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item><Link reloadDocument to="/rgb-to/uicolor">RGB to UIColor</Link></Menu.Item>
                <Menu.Item><Link reloadDocument to="/rgb-to/java">RGB to Java</Link></Menu.Item>
              </Menu.Group>
            </Menu>
          }>
            <Button marginRight={16} appearance="minimal" style={{color: 'grey'}}>Convert to Tech</Button>
          </Popover>
        <Button marginRight={16} appearance="minimal">
            <NavLink reloadDocument to={`/color-names`} activeStyle>
            Color Names
            </NavLink>
        </Button>
        <Button marginRight={16} appearance="minimal">
            <NavLink reloadDocument to={`/color-palettes`} activeStyle>
            Color Palettes
            </NavLink>
        </Button>
        <Button marginRight={16} appearance="minimal">
            <NavLink reloadDocument to={`/hex/${getRandomHexColor().replace('#', '')}`} activeStyle>
            Random Color
            </NavLink>
        </Button>
        <Button marginRight={16} appearance="minimal">
            <NavLink reloadDocument to={`/color-palette/random`} activeStyle>
            Random Color Palette
            </NavLink>
        </Button>
        </Box>
    </Stack>
    <hr />
    </>
  );
}

export default Navbar;

export const Nav = styled.nav`
  height: 65px;
  padding-top: 15px;
  background-color: #FFFFFF;
  margin-left: 15%;
  margin-right: 15%;
`;
  
export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;
  
export const Poppy = styled.div`
  display: none;
  color: #808080;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
  
export const NavMenu = styled.div`
  display: flex;
  margin: auto;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;