import styled from "styled-components";
import { Heading } from "evergreen-ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { DEFAULT_META_KEYWORDS, DEFAULT_META_DESCRIPTION } from "../../App";
import { hexToRGB } from "../../Components/ColorPicker/ColorPicker";
import { Content } from "../Home/Home";
import palettes from "../../Constants/Palettes.json"
import SmallColorPalette from "./SmallColorPalette";
import { Box } from "../About/About";
import { seo } from "../../Helpers/SEO";
import { copyToClipBoard, getWebsiteName } from "../../Helpers/HelperFunctions";
import { META_TITLE_ADDITION } from "../../Constants/Constants";


export function determineTextColor(hexCode) {
    // Convert hex to RGB
    let r = parseInt(hexCode.substring(1, 3), 16);
    let g = parseInt(hexCode.substring(3, 5), 16);
    let b = parseInt(hexCode.substring(5, 7), 16);

    // Calculate luminance
    let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Determine if color is light or dark
    if (luminance > 0.5) {
        // Color is light, return white
        return "#000000";
    } else {
        // Color is dark, return white
        return "#FFFFFF";
    }
}

function searchJSON(json, searchTerm) {
    // Replace all "-" characters in the search term with spaces.

    if (searchTerm === 'random' || searchTerm === null) { return null }

    searchTerm = searchTerm.replace(/-/g, " ");

    const values = [];

    palettes.forEach(function(obj, i) {
        if (obj.name.toUpperCase() === searchTerm.toUpperCase()) {
            values.push(obj);
            return values;
        }
    })

    if (!values[0]) { window.location.href = `http://${window.location.hostname}/404`}

    return values;
  }


function RandomColorPalette(props) {

    const {name} = useParams();
    const [colors, setColors] = useState(palettes);
    const [rando, setRando] = useState(Math.floor(Math.random()*palettes.length));
    const navigate = useNavigate();
    
    useEffect(() => {

        if (!props.displayFirstPalette) {
            ReactGA.send({ hitType: "pageview", page: "/color-palettes" });
        } else {
            if (name) {
                setColors(searchJSON(palettes, name))
                ReactGA.send({ hitType: "pageview", page: `/color-palette/${name}` });
            } else {
                setColors(rando);
                ReactGA.send({ hitType: "pageview", page: "/color-palette/random" });
            }
        }
    }, [props.displayFirstPalette, name, rando, navigate, colors])
    
    return (  
        <>
        {!props.displayFirstPalette ?
        <Helmet>
            <title>{`Color Palettes - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
            <meta
                name="description"
                content={DEFAULT_META_DESCRIPTION}
            />
            <meta
                name="keywords"
                content={DEFAULT_META_KEYWORDS}
            />
        </Helmet>
        :
            name ? 
            <Helmet>
                <title>{`${name} - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
                <meta
                    name="description"
                    content={`View color palette: ${name} with the following values: ${colors[0].colors}`}
                />
                <meta
                    name="keywords"
                    content={DEFAULT_META_KEYWORDS}
                />
            </Helmet>
            :
            <Helmet>
                <title>{`${palettes[rando].name} - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
                <meta
                    name="description"
                    content={`View color palette: ${palettes[rando].name} with the following values: ${palettes[rando].colors}`}
                />
                <meta
                    name="keywords"
                    content={DEFAULT_META_KEYWORDS}
                />
            </Helmet>
        }
        <Content style={{paddingTop: '0px', marginLeft: '0px', marginRight: '0px', marginTop: '40px'}}>
            {props.displayFirstPalette 
            ? 
            !name ?
                <Box style={{borderWidth: 0}}>
                    <Heading size={900}>{palettes[rando].name}</Heading>
                    <Heading size={700}>{palettes[rando].description}</Heading>
                </Box>
            :
                <Box style={{borderWidth: 0}}>
                    <Heading size={900}>{colors[0].name}</Heading>
                    <Heading size={700}>{colors[0].description}</Heading>
                </Box>
            : 
                <Box style={{borderWidth: 0}}>
                    <Heading size={900}>{"Color Palettes"}</Heading>
                    <Heading size={700}>{"Please enjoy all of our color palettes! Click on the right arrow to view each palette and the color codes within them. Hover to view the palette description"}</Heading>
                </Box>
            }
            {props.displayFirstPalette 
            ? 
            !name ?
                palettes[rando].colors.map(function(object, i){
                    return (
                        <Color style={{backgroundColor: object}}>
                            <Header><Button style={{fontSize: '50px', color: determineTextColor(object), marginBottom: '20px'}} variant={'unstyled'} onClick={() => copyToClipBoard(object)}>{object}</Button></Header>
                            <HideSubHeader><Button style={{fontSize: '20px', color: determineTextColor(object)}} variant={'unstyled'} onClick={() => copyToClipBoard(hexToRGB(object))}>{hexToRGB(object)}</Button></HideSubHeader>
                            <SubHeader style={{fontSize: 12, paddingTop: 20}}><Link style={{color: determineTextColor(object)}} to={`/hex/${object.replace('#','')}`}>Details →</Link></SubHeader>                     
                        </Color>
                    )
                }) :
                colors[0].colors.map(function(object, i){
                    return (
                        <Color style={{backgroundColor: object}}>
                            <Header><Button style={{fontSize: '50px', color: determineTextColor(object), marginBottom: '20px'}} variant={'unstyled'} onClick={() => copyToClipBoard(object)}>{object}</Button></Header>
                            <HideSubHeader><Button style={{fontSize: '20px', color: determineTextColor(object)}} variant={'unstyled'} onClick={() => copyToClipBoard(hexToRGB(object))}>{hexToRGB(object)}</Button></HideSubHeader>
                            <SubHeader style={{fontSize: 12, paddingTop: 20}}><Link style={{color: determineTextColor(object)}} to={`/hex/${object.replace('#','')}`}>Details →</Link></SubHeader>
                        </Color>
                    )
                })
            : 
            null
            }
            <div style={{marginTop: '50px', width: '100%', textAlign: 'center'}}>
                {palettes.map(function(obj, i) {
                    return (
                    <PaletteWrapper>
                        <SmallColorPalette palette={obj.colors} title={obj.name} desc={obj.description} style={{marginRight: '10px', marginLeft: '10px', marginTop: '30px', display: 'inline-block', margin: 'auto'}} />
                    </PaletteWrapper>
                    )
                })}
            </div>
        </Content>
        </>
    );
}

export default RandomColorPalette;

export const PaletteWrapper = styled.div`

    margin-right: 40px;
    margin-left: 40px;
    margin-top: 30px;
    display: inline-block;

    @media (max-width: 800px) {
        margin-right: 0;
        margin-left: 0;
    }  
`

export const Color = styled.section`
    width: 800px;
    height: 800px;
    display: table-cell;

    @media (max-width: 1100px) {
        width: 100%;
        height: 200px;
        display: flex;
    }  

    @media (max-width: 800px) {
        width: 100%;
        height: 200px;
        display: flex;
        padding-left: 20px;
    }  
`;


const Header = styled.h1`
    margin: auto;
    text-align: center;
    margin-top: 100%;
    font-size: 50px;
    font-weight: bold;

    @media (max-width: 1100px) {
        text-align: center;
        margin-top: 5%;
        width: 100%;
    }  

    @media (max-width: 800px) {
        text-align: center;
        margin-top: 15%;
        width: 100%;
    }  
`

const SubHeader = styled.h3`

    margin: auto;
    text-align: center;
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 1100px) {
        text-align: center;
        margin-top: 7%;
        width: 100%;
    }  

    @media (max-width: 800px) {
        text-align: center;
        margin-top: 20%;
        width: 100%;
    }  
`

const HideSubHeader = styled(SubHeader)`

    @media (max-width: 800px) {
        display: none;
    }  
`

const FlexButton = styled(Button)`

margin: auto;
margin-top: 25px;
margin-left: 35%;

@media (max-width: 1100px) {
    position: absolute;
    margin: auto;
    margin-top: 150px;
    margin-left: 12%;
}  
`