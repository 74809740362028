import { Heading } from "evergreen-ui";
import { Code } from '@chakra-ui/react'
import PrimaryColors from "../PrimaryColors";

export default function HexToHsl() {
    return (
        <div style={{textAlign: 'left'}}>
            <Heading size={700}>Convert HEX to HSL: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Convert the HEX color code to RGB using the steps outlined above.<br/>
            2. Divide the red, green, and blue components by 255 to normalize the values to a range of 0 to 1.<br/>
            3. Calculate the hue (H), saturation (S), and lightness (L) values using the following formulas:<br/>
            <Code size={500}>
                H = (G - B) / (max(R, G, B) - min(R, G, B))<br />
                S = (max(R, G, B) - min(R, G, B)) / max(R, G, B)<br />
                L = (max(R, G, B) + min(R, G, B)) / 2
            </Code>
            </p>
            <p style={{marginTop: '10px'}}>
            For example, the HEX color code <Code size={300} appearance="minimal">#FF0000</Code> represents the color red. To convert this to HSL, we would first convert it to RGB: (255, 0, 0). Then, we would divide the red, green, and blue components by 255 to get the normalized values: (1, 0, 0). Finally, we would calculate the hue, saturation, and lightness values using the formulas above:
            <br />
            <br />
            <Code size={500}>
                H = (0 - 0) / (1 - 0) = 0°<br />
                S = (1 - 0) / 1 = 100%<br />
                L = (1 + 0) / 2 = 50%
            </Code>
            <br />
            <br />
            Therefore, the HSL color code for the HEX color code <Code size={300} appearance="minimal">#FF0000</Code> is <Code size={300} appearance="minimal">(0°, 100%, 50%)</Code>.
            <br />
            <br />
            <Heading size={800}  style={{marginBottom: '10px'}}>Primary Colors</Heading>
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
    );
}