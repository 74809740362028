import { useEffect } from "react";
import { Heading, Button } from "evergreen-ui";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import { DEFAULT_META_KEYWORDS, DEFAULT_META_DESCRIPTION } from "../../App";
import { Box } from "../About/About";
import { Content } from "../Home/Home";
import { getWebsiteName } from "../../Helpers/HelperFunctions";
import { META_TITLE_ADDITION } from "../../Constants/Constants";

export default function NotFound() {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/404" });
      })
  
      return (
        <>
        <Helmet>
            <title>{`404 - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
            <meta
                name="description"
                content={DEFAULT_META_DESCRIPTION}
            />
            <meta
                name="keywords"
                content={DEFAULT_META_KEYWORDS}
            />
        </Helmet>
          <div style={{backgroundColor: '#F0F0F0'}}>
          <Content style={{backgroundColor: 'white', borderWidth: '1px', borderColor: 'gray', borderStyle: 'none solid'}}>
              <Box style={{marginBottom: '15px'}}>
                  <Heading size={900}>{'404 - Not Found'}</Heading>
                  <p style={{marginTop: '10px'}}>You either tried searching for a color that doesn't exist, or a page that doesn't exist. Please ensure you're entering the correct information!</p>
              </Box>
              <Box>
                <Button><Link to="/">Go Back Home?</Link></Button>
              </Box>
          </Content>
      </div>
      </>
      );
}