import { Heading } from "evergreen-ui";
import { Code } from "@chakra-ui/react";
import PrimaryColors from "../PrimaryColors";

export default function HexToUicolor() {
    return (
        <>
        <div style={{textAlign: 'left'}}>
            <Heading size={800}>Convert HEX to UIColor: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Remove the hash symbol (#) from the HEX color code.<br/>
            2. Divide the HEX color code into three pairs of characters.<br/>
            3. Convert each pair of characters to a decimal value using a HEX to decimal converter.<br />
            4. Divide each decimal value by 255 to normalize the values to a range of 0 to 1.<br />
            5. Create a UIColor object using the normalized red, green, and blue values.<br />
            <br />
            </p>
            <p style={{marginTop: '10px'}}>
            For example, to convert the HEX color code <Code size={300} appearance="minimal">#FF0000</Code> to a UIColor, we would first remove the hash symbol: <Code size={300} appearance="minimal">FF0000</Code>. Then, we would divide the HEX color code into three pairs of characters: <Code size={300} appearance="minimal">FF</Code>, <Code size={300} appearance="minimal">00</Code>, and <Code size={300} appearance="minimal">00</Code>. Next, we would convert each pair of characters to a decimal value: <Code size={300} appearance="minimal">FF = 255</Code>, <Code size={300} appearance="minimal">00 = 0</Code>, and <Code size={300} appearance="minimal">00 = 0</Code>. Finally, we would divide each decimal value by <Code size={300} appearance="minimal">255</Code> to normalize the values to a range of 0 to 1: <Code size={300} appearance="minimal">(1, 0, 0)</Code>.
            <br />
            <br />
            To create a <Code size={300} appearance="minimal">UIColor</Code> object using the normalized red, green, and blue values, we can use the following code:
            <br />
            <br />
            <Code size={500}>
                let red = CGFloat(1.0)<br />
                let green = CGFloat(0.0)<br />
                let blue = CGFloat(0.0)<br />
                let color = UIColor(red: red, green: green, blue: blue, alpha: 1.0)
            </Code>
            <br />
            <br />
            The <Code size={300} appearance="minimal">color</Code> variable will now contain a <Code size={300} appearance="minimal">UIColor</Code> object that represents the color red.<br/><br/>
            <Heading size={800}>UIColor Extension</Heading>
            <br />
            You can also use a variety of Swift extensions to convert HEX color codes to UIColor objects. For example, the following extension provides a convenience initializer for creating <Code size={300} appearance="minimal">UIColor</Code> objects from HEX color strings:
            <br />
            <br />
            <Code 
                style={{width: '100%'}}
                size={500}
                whiteSpace={'pre'}
                children={`
extension UIColor {
    convenience init(hexString: String, alpha: CGFloat = 1.0) {
        let scanner = Scanner(string: hexString)
        if scanner.scanHexInt32(&color) {
            self.init(red: CGFloat(red >> 24 & 0xFF) / 255.0,
                        green: CGFloat(red >> 16 & 0xFF) / 255.0,
                        blue: CGFloat(red >> 8 & 0xFF) / 255.0,
                        alpha: alpha)
        } else {
            self.init(white: 0.0, alpha: alpha)
        }
    }
}
            `}
            />
            <br />
            <br />
            To use this extension, you can simply pass the HEX color code to the <Code size={300} appearance="minimal">UIColor(hexString:)</Code> initializer. For example, the following code will create a UIColor object that represents the color red:
            <br />
            <br />
            <Code size={500} appearance={'minimal'}>let color = UIColor(hexString: "#FF0000")</Code>
            <br />
            <br />
            <Heading size={800}  style={{marginBottom: '10px'}}>Primary Colors</Heading>
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
        </>
    );
}