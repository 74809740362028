import { toaster } from "evergreen-ui";
import { WEBSITE_NAME } from "../Constants/Constants";

export const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 900px)").matches

export const copyToClipBoard = async copyMe => {
    try {
        /*
        if (!isMobile) {
            await navigator.clipboard.writeText(copyMe);
            toaster.success('Copied to clipboard')
        } else {
            toaster.warning('Could not copy bc not')
        }*/
        await navigator.clipboard.writeText(copyMe);
        toaster.success('Copied to clipboard')
    } catch (err) {
        toaster.danger('Could not copy bc err')
    }
};

export const getWebsiteName = (WEBSITE_NAME === 'htmlcolors.co' ? "HTML Colors" : "CSS Colors")