import { Heading, Table, toaster, Button } from "evergreen-ui";
import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Code } from "evergreen-ui";
import { Component } from "react";

import { ColorSquare } from "../../Pages/About/About";
import ColorCodes from "../../Constants/HTMLColorCodes.json"
import ColorNames from "../../Constants/HTMLColorNames.json"
import { getRandomHexColor } from "../../Constants/ColorFunctions";
import { TableWrapper } from "../../Pages/About/About";

export const hexToRGB = (hexColor) => {
    hexColor = hexColor.replace('#', '');
    const numericValue = parseInt(hexColor, 16);
    const r = numericValue >> 16 & 0xFF;
    const g = numericValue >> 8 & 0xFF;
    const b = numericValue & 0xFF;
    return `${r}, ${g}, ${b}`
  }

export default class Names extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: getRandomHexColor(),
        }
      };
    
      render() {
        return (
            <>
                <div style={{width: '100%', margin: 'auto'}}>
                    <Box>
                        <Heading size={900} style={{marginTop: '30px'}}>Blues</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['blues']).map(function(obj, i) {
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Greens</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['greens']).map(function(obj, i) {
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Reds</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['reds']).map(function(obj, i) {
                                    console.log(ColorCodes[obj])
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Yellows</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['yellows']).map(function(obj, i) {
                                    console.log(ColorCodes[obj])
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Purples</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['purples']).map(function(obj, i) {
                                    console.log(ColorCodes[obj])
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Pinks</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['pinks']).map(function(obj, i) {
                                    console.log(ColorCodes[obj])
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Oranges</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['oranges']).map(function(obj, i) {
                                    console.log(ColorCodes[obj])
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Grays</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['grays']).map(function(obj, i) {
                                    console.log(ColorCodes[obj])
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Whites</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['whites']).map(function(obj, i) {
                                    console.log(ColorCodes[obj])
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                    <Box style={{marginTop: '60px'}}>
                        <Heading size={900} style={{marginTop: '30px'}}>Others</Heading>
                        <TableWrapper>
                            <Table.Body>
                                {Object.values(ColorNames['others']).map(function(obj, i) {
                                    console.log(ColorCodes[obj])
                                    return (
                                        <Table.Row height="auto">
                                            <Table.TextCell>
                                                <Link reloadDocument to={`/hex/${ColorCodes[obj].hex.replace('#','')}`} style={{width: '100px', height: '100px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: ColorCodes[obj].hex, height: '100px', width: '100px'}} /></Link>
                                            </Table.TextCell>
                                            <Table.TextCell style={{marginTop: '10px'}}>
                                                <Heading size={500} style={{textAlign: 'center'}}>{ColorCodes[obj].name}</Heading>
                                                <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText(ColorCodes[obj].hex)
                                                toaster.success('Copied to clipboard')} }>
                                                    <Code size={400}>{ColorCodes[obj].hex.toUpperCase()}</Code>
                                                </Button>
                                                <h3 style={{textAlign: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', marginTop: '10px', marginBottom: '10px'}}>{ColorCodes[obj].description}</h3 >
                                            </Table.TextCell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </TableWrapper>
                    </Box>
                </div>
            </>
        );
      }
}