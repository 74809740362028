import { Heading } from "evergreen-ui";
import PrimaryColors from "../PrimaryColors";
import { Code } from "@chakra-ui/react";

export default function RgbToHsl() {
    return (
        <div style={{textAlign: 'left'}}>
            <Heading size={800}>Convert RGB to HSL: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Divide the red, green, and blue components by 255 to normalize the values to a range of 0 to 1.<br/>
            2. Calculate the hue (H), saturation (S), and lightness (L) values using the following formulas:
            <br />
            <br />
            <Code size={500}>
                H = (G - B) / (max(R, G, B) - min(R, G, B))<br />
                S = (max(R, G, B) - min(R, G, B)) / max(R, G, B)<br />
                L = (max(R, G, B) + min(R, G, B)) / 2
            </Code>
            3. The hue value is represented in degrees, while the saturation and lightness values are represented as percentages.
            </p>
            <p style={{marginTop: '10px'}}>
            For example, the RGB color code <Code size={300} appearance="minimal">(255, 0, 0)</Code> represents the color red. To convert this to HSL, we would first normalize the red, green, and blue components: <Code size={300} appearance="minimal">(1, 0, 0)</Code>. Then, we would calculate the hue, saturation, and lightness values using the formulas above:
            <br />
            <br />
            <Code size={500}>
                H = (0 - 0) / (1 - 0) = 0°<br />
                S = (1 - 0) / 1 = 100%<br />
                L = (1 + 0) / 2 = 50%
            </Code>
            <br />
            <br />
            Therefore, the HSL color code for the RGB color code <Code size={300} appearance="minimal">(255, 0, 0)</Code> is <Code size={300} appearance="minimal">(0°, 100%, 50%)</Code>.
            <br />
            <br />
            <Heading size={800}  style={{marginBottom: '10px'}}>Primary Colors</Heading>
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
    );
}