import React from "react";
import { useEffect } from "react";
import { Heading } from "evergreen-ui";
import { Tabs, TabPanel, TabList, TabPanels, Tab } from "@chakra-ui/react";
import { InputGroup, Input, InputRightElement, Button } from "@chakra-ui/react";
import { toaster, Code } from "evergreen-ui";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import { DEFAULT_META_KEYWORDS, DEFAULT_META_DESCRIPTION } from "../../App";
import { validateHexCode } from "../../Pages/Convert/Convert";
import { Box, ColorSquare } from "../../Pages/About/About";
import { Content } from "../../Pages/Home/Home";
import { hexToUIColorSwift, convertHexToAndroidColor } from "../../Constants/ColorFunctions";
import HexToUicolor from "../../Pages/Convert/Information/HEXTECH/HexToUicolor";
import HexToJava from "../../Pages/Convert/Information/HEXTECH/HexToJava";

import { PaletteWrapper } from "../../Pages/ColorPalette/RandomColorPalette";
import { SecondaryPaletteWrapper } from "../ColorPicker/ColorPicker";
import SmallColorPalette from "../../Pages/ColorPalette/SmallColorPalette";
import { ArrowRightIcon } from "evergreen-ui";
import PaletteData from "../../Constants/Palettes.json"
import { getWebsiteName } from "../../Helpers/HelperFunctions";
import { META_TITLE_ADDITION } from "../../Constants/Constants";

export default function HexToTech(props) {
    const defaultColorCode = '#df95ff';
    const [colorCode, setColorCode] = React.useState('#df95ff');
    const [title, setTitle] = React.useState();
    const [tabTitle, setTabTitle] = React.useState();
    const [uicolor, setUIColor] = React.useState(hexToUIColorSwift('#df95ff'));
    const [javacolor, setJavaColor] = React.useState(convertHexToAndroidColor('#df95ff'));

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/hex-to/${props.code}` });
    })

    return(
        <>
        <Helmet>
                <title>{`HEX to ${props.code} - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
                <meta
                    name="description"
                    content={"Convert your HEX or RGB color codes into many different tech related color variables such as UIColor for Swift/Objective-C and Java"}
                />
                <meta
                    name="keywords"
                    content={DEFAULT_META_KEYWORDS}
                />
        </Helmet>
        <div style={{backgroundColor: '#F0F0F0'}}>
            <Content style={{backgroundColor: 'white', borderWidth: '0.1px', borderColor: 'gray', borderStyle: 'none solid'}}>
                <div style={{margin: 'auto'}}>
                <div style={{textAlign: 'center'}}>
                    <Heading size={900} style={{marginBottom: '20px'}}>Convert a <Code size={900} appearance="minimal">HEX</Code> Color to...</Heading>
                    <Tabs variant='soft-rounded' colorScheme='blue' align="center" defaultIndex={props.code === 'UIColor' ? 0 : 1}>
                        <TabList>
                            <Tab>UIColor</Tab>
                            <Tab>Java</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Box>
                                    <InputGroup size='lg'>
                                        <Input
                                            pr='4.5rem'
                                            type={'text'}
                                            placeholder={`Enter HEX Code`}
                                            onChange={(e) => {
                                                setColorCode(e.target.value)
                                                if (hexToUIColorSwift(e.target.value) === null) {
                                                    toaster.warning('Invalid HEX Value', {
                                                        id: 'forbidden'
                                                    })
                                                    setUIColor(hexToUIColorSwift(defaultColorCode))
                                                    setJavaColor(convertHexToAndroidColor(defaultColorCode))
                                                } else {
                                                    setUIColor(hexToUIColorSwift(e.target.value))
                                                    setJavaColor(convertHexToAndroidColor(e.target.value))
                                                }
                                            }}
                                            value={colorCode}
                                        />
                                        <InputRightElement width='4.5rem'>
                                            <Button h='2.5rem' size='sm'
                                            style={{
                                                marginRight: '10px'
                                            }}>
                                            {'Convert'}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                <div style={{marginTop: '20px'}}>
                                    <Heading size={900}>{"UIColor:"}</Heading>
                                    <Button style={{padding: 10, marginTop: '10px'}} appearance="minimal" onClick={() => {
                                        navigator.clipboard.writeText(uicolor)
                                        toaster.success('Copied to clipboard')} }>
                                            <Code size={900} appearance="minimal">{uicolor}</Code>
                                    </Button>
                                    <Link to={`/hex/${colorCode.replace('#','')}`}><ColorSquare style={{backgroundColor: validateHexCode(colorCode), width: '200px', height: '200px', margin: 'auto', marginTop: '30px'}} /></Link>
                                </div>
                                </Box>
                                <Box style={{marginTop: '20px'}}>
                                    <HexToUicolor />
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Box>
                                    <InputGroup size='lg'>
                                        <Input
                                            pr='4.5rem'
                                            type={'text'}
                                            placeholder={`Enter HEX Code`}
                                            onChange={(e) => {
                                                setColorCode(e.target.value)
                                                if (convertHexToAndroidColor(e.target.value) === null) {
                                                    toaster.warning('Invalid HEX Value', {
                                                        id: 'forbidden'
                                                    })
                                                    setUIColor(hexToUIColorSwift(defaultColorCode))
                                                    setJavaColor(convertHexToAndroidColor(defaultColorCode))
                                                } else {
                                                    setUIColor(hexToUIColorSwift(e.target.value))
                                                    setJavaColor(convertHexToAndroidColor(e.target.value))
                                                }
                                            }}
                                            value={colorCode}
                                        />
                                        <InputRightElement width='4.5rem'>
                                            <Button h='2.5rem' size='sm'
                                            style={{
                                                marginRight: '10px'
                                            }}>
                                            {'Convert'}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                <div style={{marginTop: '20px'}}>
                                    <Heading size={900}>{"Java:"}</Heading>
                                    <Button style={{padding: 10, marginTop: '10px'}} appearance="minimal" onClick={() => {
                                        navigator.clipboard.writeText(javacolor)
                                        toaster.success('Copied to clipboard')} }>
                                            <Code size={900} appearance="minimal">{javacolor}</Code>
                                    </Button>
                                    <Link to={`/hex/${colorCode.replace('#','')}`} style={{width: '200px', height: '200px', margin: 0, padding: 0}}><ColorSquare style={{backgroundColor: validateHexCode(colorCode), width: '200px', height: '200px', margin: 'auto', marginTop: '30px', borderColor: 'gray'}} /></Link>
                                </div>
                                </Box>
                                <Box style={{marginTop: '20px'}}>
                                    <HexToJava />
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>
                </div>
                <SecondaryPaletteWrapper>
                    {PaletteData.map(function(obj, i) {
                        if (i >= 10) { return null }
                        return (
                            <PaletteWrapper>
                                <SmallColorPalette palette={obj.colors} title={obj.name} desc={obj.description} style={{display: 'inline-block'}} />
                            </PaletteWrapper>
                        )
                    })}
                </SecondaryPaletteWrapper>
                <Button marginY={8}  iconAfter={ArrowRightIcon} appearance="primary" style={{float: 'right', marginRight: 20}}>
                    View More Palettes
                </Button>
            </Content>
        </div>
        </>
    );
}



/*
Choosing the right colors for your mobile app is important for several reasons:

Colors can evoke emotions: Different colors can evoke different emotions in users. For example, red is often associated with excitement and energy, while blue is often associated with calmness and trust. By carefully selecting your app's colors, you can create an emotional connection with your users and influence their perception of your app.
Colors can improve usability: Colors can also be used to improve the usability of your app. For example, using high-contrast colors can make it easier for users to read text and distinguish between different elements of your app's interface. Additionally, using color-coding can help users to quickly identify and navigate different features of your app.
Colors can create a brand identity: Colors can also be used to create a strong brand identity for your app. By using a consistent color palette throughout your app, you can create a visually appealing and cohesive experience for your users. Additionally, by using distinctive colors that are associated with your brand, you can help users to recognize and remember your app.
Here are some examples of how colors can be used effectively in mobile app design:

A social media app might use bright and vibrant colors to create a sense of excitement and engagement.
A productivity app might use more subdued and muted colors to create a sense of calm and focus.
An educational app might use a variety of colors to represent different subjects or concepts.
A gaming app might use colors to create a specific atmosphere or mood.
When choosing colors for your mobile app, it is important to consider the following factors:

The purpose of your app: What is the main function of your app? What kind of experience do you want to create for your users?
Your target audience: Who are you designing your app for? What are their preferences and expectations?
Your brand identity: What are the colors associated with your brand? How can you use color to reinforce your brand identity?
It is also important to test different color combinations to see how they look on different devices and screens. Additionally, you should get feedback from other people to see how they react to your app's colors.

Here are some tips for choosing good colors for your mobile app design:

Use complementary colors: Complementary colors are colors that are opposite each other on the color wheel. When placed next to each other, complementary colors create a strong contrast that can be visually appealing.
Use a variety of hues: Avoid using too many of the same colors in your app's design. Instead, use a variety of hues to create a more interesting and visually appealing experience for your users.
Use color-coding: Color-coding can help users to quickly identify and navigate different features of your app. For example, you could use different colors to represent different categories of content or different types of actions.
Use color to create a hierarchy: You can use color to create a hierarchy in your app's design. For example, you could use brighter colors to highlight important elements of your app's interface or to draw attention to specific calls to action.
By following these tips, you can choose the right colors for your mobile app and create a visually appealing and user-friendly experience for your users.
*/