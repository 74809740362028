import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, extendTheme, defineStyle, defineStyleConfig } from "@chakra-ui/react";
import styled from "styled-components";
import { Table, Heading, Code, toaster, Strong, ListItem, TickCircleIcon } from "evergreen-ui";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import { DEFAULT_META_KEYWORDS, DEFAULT_META_TITLE, DEFAULT_META_DESCRIPTION } from "../../App";
import { seo } from "../../Helpers/SEO";
import { Content } from "../Home/Home";
import colorData from "../../Constants/HTMLColorCodes.json"
import HexToRgb from "../Convert/Information/HEX/HexToRgb";
import { determineTextColor } from "../ColorPalette/RandomColorPalette";
import { getWebsiteName } from "../../Helpers/HelperFunctions";
import { hexToRGB } from "../ColorNames/Names";
import { hexToCSS, hexToCmyk, hexToHSL, hexToHsv } from "../../Constants/ColorFunctions";
import { META_TITLE_ADDITION } from "../../Constants/Constants";

const brandPrimary = defineStyle({
  background: 'orange.500',
  color: 'white',
  fontFamily: 'serif',
  fontWeight: 'normal',
})

export const buttonTheme = defineStyleConfig({
  variants: { brandPrimary },
})

function isHexColor (hex) {
    return typeof hex === 'string'
        && hex.length === 6
        && !isNaN(Number('0x' + hex))
}

export function generateMonochromaticColors(hexColor) {
  // Convert HEX to RGB
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate the luminance (brightness) of the color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  const step = luminance > 0.5 ? -30 : 30; // Adjust this value to change the intensity of the colors
  const numShades = 8; // Adjust this value to get more or fewer shades

  const shades = [];

  for (let i = 0; i < numShades; i++) {
    const newR = r + i * step;
    const newG = g + i * step;
    const newB = b + i * step;

    // Ensure the values are within the valid range (0-255)
    const finalR = Math.min(Math.max(newR, 0), 255);
    const finalG = Math.min(Math.max(newG, 0), 255);
    const finalB = Math.min(Math.max(newB, 0), 255);

    // Convert RGB back to HEX
    const newHexColor = `#${finalR.toString(16).padStart(2, '0')}${finalG.toString(16).padStart(2, '0')}${finalB.toString(16).padStart(2, '0')}`;
    shades.push(newHexColor);
  }

  return shades;
}



  function findComplementaryColor(hexColor) {
    // Convert HEX to RGB
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);
  
    // Calculate complementary color
    const complementaryR = 255 - r;
    const complementaryG = 255 - g;
    const complementaryB = 255 - b;
  
    // Convert RGB back to HEX
    const complementaryHexColor = `#${complementaryR.toString(16).padStart(2, '0')}${complementaryG.toString(16).padStart(2, '0')}${complementaryB.toString(16).padStart(2, '0')}`;
  
    return complementaryHexColor;
  }
  
  
  function findAnalogousColors(hexColor) {
    const angle = 30; // Adjust this value to change the angle between colors
    const numAnalogous = 3; // Adjust this value to get more or fewer analogous colors
  
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);
    

    const hslColor = rgbToHsl(r, g, b);
    const analogousColors = [];

    for (let i = 1; i <= numAnalogous; i++) {
      const newHue = (hslColor[0] + i * angle) % 360;
      const newRgb = hslToRgb(newHue, hslColor[1], hslColor[2]);
      console.log(newRgb)
      const newHexColor = `#${rgbToHex(newRgb[0], newRgb[1], newRgb[2])}`;
      analogousColors.push(newHexColor);
    }
  
    return analogousColors;
  }
  
  function rgbToHsl(r, g, b) {
    // Normalize RGB values to range [0, 1]
    r /= 255;
    g /= 255;
    b /= 255;

    // Find the maximum and minimum values
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);

    // Calculate lightness
    let l = (max + min) / 2;

    let s, h;
    if (max === min) {
        // Achromatic (gray)
        s = 0;
        h = 0; // Hue is undefined in this case
    } else {
        // Chromatic calculation
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = ((g - b) / d) + (g < b ? 6 : 0);
                break;
            case g:
                h = ((b - r) / d) + 2;
                break;
            case b:
                h = ((r - g) / d) + 4;
                break;
        }

        h /= 6;
    }

    // Convert h, s, l values to integers without decimals
    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    return [ h, s, l ];
}

function hslToRgb(h, s, l) {
  // Convert h, s, l values to the range [0, 1]
  h = h / 360;
  s = s / 100;
  l = l / 100;

  let r, g, b;

  if (s === 0) {
      // Achromatic (gray)
      r = g = b = l;
  } else {
      const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
  }

  // Convert r, g, b values to integers without decimals
  r = Math.round(r * 255);
  g = Math.round(g * 255);
  b = Math.round(b * 255);

  return [ r, g, b ];
}

  
  function rgbToHex(r, g, b) {
    return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  }
  

  export function findClosestColor(hexColor) {
  
    let closestColor = null;
    let minDifference = Infinity;
  
    for (const colorName in colorData) {
      const color = colorData[colorName];
      console.log("COLOR: " + color)
      const r = parseInt(color.rgb.split(',')[0], 10);
      const g = parseInt(color.rgb.split(',')[1], 10);
      const b = parseInt(color.rgb.split(',')[2], 10);

      const colorDifference = Math.sqrt(
        Math.pow(r - parseInt(hexColor.substring(1, 3), 16), 2) +
        Math.pow(g - parseInt(hexColor.substring(3, 5), 16), 2) +
        Math.pow(b - parseInt(hexColor.substring(5, 7), 16), 2)
      );
  
      if (colorDifference < minDifference) {
        minDifference = colorDifference;
        closestColor = colorName;
      }
    }
  
    return closestColor;
  }
  



function About() {
    const {code} = useParams();
    var colorCode = '#'+code

    const isHex = isHexColor(code)

    if (!isHex) { window.location = "/404"}

    console.log("isHex: " + isHex)

    const newColorArr = generateMonochromaticColors(colorCode)
    const complementary = findComplementaryColor(colorCode)
    const analogous = findAnalogousColors(colorCode)
    const colorName = findClosestColor(colorCode)

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: `/hex/${code}: ${META_TITLE_ADDITION}` });
    })

    return (
      <>
      <Helmet>
          <title>{`${colorName}: #${code.toUpperCase()} - ` + getWebsiteName}</title>
          <meta
              name="description"
              content={`#${code.toUpperCase()} or ${hexToRGB(code)}: ${colorName} - ${colorData[colorName].description}`}
          />
          <meta
              name="keywords"
              content={DEFAULT_META_KEYWORDS}
          />
      </Helmet>
        <div style={{backgroundColor: '#F0F0F0'}}>
        <Content style={{backgroundColor: 'white', borderWidth: '1px', borderColor: 'gray', borderStyle: 'none solid', paddingTop: 30}}>
            <Box>
                <CHeader>{colorName}</CHeader>
                <Space />
                <Code size={800}>#{code.toUpperCase()}</Code>
                <Heading size={400} marginTop={10}>{colorData[colorName].description}</Heading>
            </Box>
            <Space />
            <TableWrapper>
                <Table.Body>
                    {newColorArr.map(function(object, i){
                        return (
                            <Table.Row height="auto">
                                <Table.TextCell>
                                    <ColorSquare style={{backgroundColor: object}} />
                                </Table.TextCell>
                                <Table.TextCell style={{height: '110px'}}>
                                    <Heading size={500}>{colorName+" 00"+i}</Heading>
                                    <Button style={{padding: 0, width: '100%', marginTop: '10px'}} appearance="minimal" onClick={() => {
                                      navigator.clipboard.writeText(object)
                                      toaster.success('Copied to clipboard')} }>
                                        <Code size={400} appearance="minimal">{object.toUpperCase()}</Code>
                                    </Button>
                                </Table.TextCell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </TableWrapper>
            <Space />
            <Box style={{margin: 'auto', width: '90%'}}>
                <CSubHeader>Complementary Color</CSubHeader>
                <Table.Body>
                    <Table.Row height="auto">
                                <Table.TextCell>
                                    <ColorSquare style={{backgroundColor: complementary}} />
                                </Table.TextCell>
                                <Table.TextCell style={{height: '80px'}}>
                                    <Heading size={500}>{findClosestColor(`${complementary}`)}</Heading>
                                    <Button style={{padding: 0, width: '100%', marginTop: '5px'}} appearance="minimal" onClick={() => {
                                      navigator.clipboard.writeText(complementary)
                                      toaster.success('Copied to clipboard')} }>
                                        <Code size={400} appearance="minimal">{complementary.toUpperCase()}</Code>
                                    </Button>
                                </Table.TextCell>
                            </Table.Row>
                </Table.Body>
            </Box>
            <Space />
            <Box style={{margin: 'auto', width: '90%'}}>
                <CSubHeader>Analogous Colors</CSubHeader>
                <Table.Body>
                    {analogous.map(function(object, i){
                        return (
                            <Table.Row height="auto">
                                <Table.TextCell>
                                    <ColorSquare style={{backgroundColor: object}} />
                                </Table.TextCell>
                                <Table.TextCell style={{height: '80px'}}>
                                    <Heading size={500}>{findClosestColor(`${object}`)}</Heading>
                                    <Button style={{padding: 0, width: '100%', marginTop: '5px'}} appearance="minimal" onClick={() => {
                                      navigator.clipboard.writeText(object)
                                      toaster.success('Copied to clipboard')} }>
                                        <Code size={400} appearance="minimal">{object.toUpperCase()}</Code>
                                    </Button>
                                </Table.TextCell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Box>
            <Box style={{marginTop: '20px'}}>
              <CSubHeader>Examples</CSubHeader>
              <Button style={{backgroundColor: '#'+code, color: determineTextColor('#'+code), marginLeft: '10px', marginTop: '10px'}}>#{code.toUpperCase()} - Primary</Button>
              <Button style={{backgroundColor: newColorArr[1], color: determineTextColor(newColorArr[1]), marginLeft: '10px', marginTop: '10px'}}>{newColorArr[1].toUpperCase()} - {colorName+" 002"}</Button>
              <Button style={{backgroundColor: newColorArr[2], color: determineTextColor(newColorArr[2]), marginLeft: '10px', marginTop: '10px'}}>{newColorArr[2].toUpperCase()} - {colorName+" 003"}</Button>
              <Button style={{backgroundColor: newColorArr[3], color: determineTextColor(newColorArr[3]), marginLeft: '10px', marginTop: '10px'}}>{newColorArr[3].toUpperCase()} - {colorName+" 004"}</Button>
              <Button style={{backgroundColor: complementary, color: determineTextColor(complementary), marginLeft: '10px', marginTop: '10px'}}>{complementary.toUpperCase()} - Complementary</Button>
              <Box style={{marginTop: '20px'}}><p style={{color: "#"+code, fontWeight: 500}}>Text Example: {"#"+code.toUpperCase()}</p></Box>
              <Box style={{marginTop: '20px'}}><p style={{color: complementary, fontWeight: 500}}>Text Example: {complementary.toUpperCase()}</p></Box>
            </Box>
            <Box style={{marginTop: '20px', paddingBottom: '30px'}}>
              <CSubHeader>Color Information</CSubHeader>
              <ListItem icon={TickCircleIcon} iconColor="success"></ListItem><Strong size={500} style={{marginLeft: '30px'}}>The selected color (#{code.toUpperCase()}) colsely resembles the shade of <span style={{color: '#'+code}}>{colorName}</span>. This color is seen as "{colorData[colorName].description}"
              </Strong>
              <br />
              <br />
              <Strong size={500}>
              The complementary color of {colorName} (#{code.toUpperCase()}) is {complementary.toUpperCase()} or {hexToRGB(complementary)} as viewed above. Complementary colors simply recongize the "opposite" or the contrast of a specific color. 
              <br />
              Analogous colors are groups of colors that are next to each other on the color wheel. For example, red, orange, and red-orange are analogous colors. They have similar hues, but they vary in saturation and lightness.
              Analogous color schemes are often used in design because they create a harmonious and unified look. Analogous colors can be used to create a variety of effects, such as a sense of warmth, coolness, or energy. The current analogous colors for {colorName} (#{code.toUpperCase()}) is: {analogous[0]}, {analogous[1]}, and {analogous[2]}.
              You can also view the color palette above!
              </Strong>
              <CSubHeader style={{fontSize: '20px', marginTop: '30px'}}>Conversions</CSubHeader>
              <Strong size={500}>
              {colorName} (#{code.toUpperCase()}) can be converted into many different color values suchs as RGB, HSL, HSV, CYMK, and more. You can view our other pages to convert these values into any specific value you desire.
              <br />
              <br />
              For example, #{code.toUpperCase()} as a RGB value can be seen as: {hexToRGB(code)}. Each number holds a different value of each primary color (red, green, and blue). As a HSL color value, this would be seen as: {hexToHSL(code)} and HSV would be: {hexToHsv(code)}. These are alternatives to the RGB color value format. Each hue are stored in a radial value on the color chart.
              Finally, #{code.toUpperCase()} as a CYMK color would be represented as: {hexToCmyk(code)}. CYMK is a stored color value that is used in color printing and the printing process itself. CMYK refers to the four ink plates used: cyan, magenta, yellow, and key.
              <br />
              <br />
              {colorName} (#{code.toUpperCase()}) can also be converted into color values that are used in technology and other forms of web projects. For example, if we wanted to convert this color to use it in CSS we would simply call the HEX (#{code.toUpperCase()}) or RGB value ({hexToRGB(code)}):
              <br />
              <Code>{hexToCSS(code)}</Code> Another example would be converting this color code into a value that is used in mobile development such as Swift or Java. These pages can be found above after inputting a specific HEX or RGB value.
              </Strong>
            </Box>
            <Box style={{marginTop: '20px'}}>
              <HexToRgb />
            </Box>
        </Content>
    </div>
    </>
    );
}

export const ColorSquare = styled.div`
  height: 65px;
  width: 65px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Box = styled.div`
    border: 2px solid #F2F2F2;
    border-radius: 5px;
    padding: 20px;
    margin-left: 20px;
    margin-right: 20px;
`

export const Space = styled.div`
    height: 20px;

  @media (max-width: 800px) {
      height: 10px;
  }  
`

export const TableWrapper = styled.div`
  width: 70%;
  margin: auto;
  margin-top: 30px;

  @media (max-width: 800px) {
    width: 90%;
    margin-top: 10px;
  }  
`

const CHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  color: black;

  @media (max-width: 800px) {
    font-size: 30px;
  }  
`

const CSubHeader = styled.h1`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
  color: black;

  @media (max-width: 800px) {
    font-size: 30px;
  }  
`
  
export default About;



export const theme = extendTheme({
    colors: {
      brand: {
        50: '#ff0000',
        500: '#ff0000',
        900: '#ff0000',
      }
    }
  })