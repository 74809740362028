import styled from "styled-components";
import React from "react";
import ReactGA from "react-ga4";

import ColorPicker from "../../Components/ColorPicker/ColorPicker";


class Home extends React.Component {
  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }

  render() {
      return (
        <div style={{backgroundColor: '#F0F0F0'}}>
          <ColorPicker />
        </div>
      );
  }
}

export default Home;

export const Content = styled.div`
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 100px;
  height: auto;
  min-height: 900px;
  overflow: auto;

  @media (max-width: 800px) {
    margin-left: 0;
    margin-right: 0;
  }  
`;
  

