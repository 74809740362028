import { Table, Code, Button, toaster } from "evergreen-ui";
import { ColorSquare } from "../../About/About";

export default function PrimaryColors() {
    return (
        <Table.Body style={{marginTop: '10px'}}>
        <Table.Row height="auto">
            <Table.TextCell>
                <ColorSquare style={{backgroundColor: 'red'}} />
            </Table.TextCell>
            <Table.TextCell style={{height: '80px'}}>
                <Button style={{padding: 0, width: '100%', marginTop: '5px'}} appearance="minimal" onClick={() => {
                    navigator.clipboard.writeText('#FF0000')
                    toaster.success('Copied to clipboard')} }>
                    <Code size={400} appearance="minimal">{'#FF0000'}</Code>
                </Button>
            </Table.TextCell>
            <Table.TextCell style={{height: '80px'}}>
                <Button style={{padding: 0, width: '100%', marginTop: '5px'}} appearance="minimal" onClick={() => {
                    navigator.clipboard.writeText('255, 0, 0')
                    toaster.success('Copied to clipboard')} }>
                    <Code size={400} appearance="minimal">{'255, 0, 0'.toUpperCase()}</Code>
                </Button>
            </Table.TextCell>
        </Table.Row>
        <Table.Row height="auto">
            <Table.TextCell>
                <ColorSquare style={{backgroundColor: 'green'}} />
            </Table.TextCell>
            <Table.TextCell style={{height: '80px'}}>
                <Button style={{padding: 0, width: '100%', marginTop: '5px'}} appearance="minimal" onClick={() => {
                    navigator.clipboard.writeText('#00FF00')
                    toaster.success('Copied to clipboard')} }>
                    <Code size={400} appearance="minimal">{'#00FF00'.toUpperCase()}</Code>
                </Button>
            </Table.TextCell>
            <Table.TextCell style={{height: '80px'}}>
                <Button style={{padding: 0, width: '100%', marginTop: '5px'}} appearance="minimal" onClick={() => {
                    navigator.clipboard.writeText('0, 255, 0')
                    toaster.success('Copied to clipboard')} }>
                    <Code size={400} appearance="minimal">{'0, 255, 0'.toUpperCase()}</Code>
                </Button>
            </Table.TextCell>
        </Table.Row>
        <Table.Row height="auto">
            <Table.TextCell>
                <ColorSquare style={{backgroundColor: 'blue'}} />
            </Table.TextCell>
            <Table.TextCell style={{height: '80px'}}>
                <Button style={{padding: 0, width: '100%', marginTop: '5px'}} appearance="minimal" onClick={() => {
                    navigator.clipboard.writeText('#0000FF')
                    toaster.success('Copied to clipboard')} }>
                    <Code size={400} appearance="minimal">{'#0000FF'.toUpperCase()}</Code>
                </Button>
            </Table.TextCell>
            <Table.TextCell style={{height: '80px'}}>
                <Button style={{padding: 0, width: '100%', marginTop: '5px'}} appearance="minimal" onClick={() => {
                    navigator.clipboard.writeText('0, 0, 255')
                    toaster.success('Copied to clipboard')} }>
                    <Code size={400} appearance="minimal">{'0, 0, 255'.toUpperCase()}</Code>
                </Button>
            </Table.TextCell>
        </Table.Row>
    </Table.Body>
    );
}