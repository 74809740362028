import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'evergreen-ui'
import { Heading } from 'evergreen-ui';
  
const Footer = () => {

  return (
    <>
      <hr />
      <Nav style={{paddingTop: '30px'}}>
        <div style={{textAlign: 'center'}}>
            <Heading size={200} style={{marginBottom: '5px', color: 'black'}}>Created by</Heading>
            <Button marginRight={1} intent="none" appearance='minimal' style={{marginRight: '15px'}}>
                <NavLink target="_blank" rel="noopener noreferrer" to={`http://www.yea.dev`} style={{color: '#5571e8'}}>yea.dev</NavLink>
            </Button>
            <Button marginRight={1} intent="none" appearance='minimal'>
                <NavLink target="_blank" rel="noopener noreferrer" to={`http://www.skyroth.com`} style={{color: '#5571e8'}}>Sky Roth</NavLink>
            </Button>
            <Heading size={200} style={{marginTop: '10px', marginBottom: '10px', color: 'black'}}>Copyright © 2023 <Link to={window.location.hostname} style={{color: 'grey'}}>{window.location.hostname}</Link></Heading>
        </div>
      </Nav>
    </>
  );
};

export default Footer;

export const Nav = styled.nav`
  padding-top: 15px;
  background-color: #FFFFFF;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 20px;
`;
  
export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;
  
export const Poppy = styled.div`
  display: none;
  color: #808080;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
  
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;