import { Heading } from "evergreen-ui";
import { Code } from "@chakra-ui/react";
import PrimaryColors from "../PrimaryColors";

export default function RgbToJava() {
    return (
        <div style={{textAlign: 'left'}}>
            <Heading size={800}>Convert RGB to Java: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Create a new instance of the Color class, passing the red, green, and blue components as arguments.<br/>
            2. Specify the alpha component, if desired. The alpha component controls the transparency of the color.<br/>
            3. Assign the Color object to a variable.
            <br />
            </p>
            <p style={{marginTop: '10px'}}>
            For example, to convert the RGB color code <Code size={300} appearance="minimal">(255, 0, 0)</Code> to a Java color variable, you would use the following code:
            <br />
            <br />
            To create a <Code size={300} appearance="minimal">UIColor</Code> object using the normalized red, green, and blue values, we can use the following code:
            <br />
            <br />
            <Code 
                size={500} 
                whiteSpace={'pre'}
                children={`Color color = new Color(255, 0, 0);

//  Or you can define the HEX color code:

Color.valueOf(0xffffff)`} />
            <br />
            <br />
            The <Code size={300} appearance="minimal">color</Code> variable will now contain a <Code size={300} appearance="minimal">UIColor</Code> object that represents the color red.<br/><br/>
            <Heading size={800}>The Alpha Component</Heading>
            <br />
            The alpha component is a floating-point value between <Code size={300} appearance="minimal">0.0</Code> and <Code size={300} appearance="minimal">1.0</Code>, where <Code size={300} appearance="minimal">0.0</Code> is completely transparent and <Code size={300} appearance="minimal">1.0</Code> is completely opaque.
            <br />
            <br />
            Once you have created a Color object, you can assign it to a variable and use it in your code. For example, the following code will set the background color of a frame to red:
            <br />
            <br />
            <Code 
                size={500} 
                whiteSpace={'pre'}
                children={`Frame frame = new Frame();
frame.setBackground(color);`} />
            <br />
            <br />
            You can also use Color objects to create other graphical objects, such as lines, shapes, and text.
            <br />
            <br />
            <Heading size={800}>Primary Colors</Heading>
            <br />
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
    );
}