import React, { useEffect } from "react";
import { Tabs, TabPanel, TabList, TabPanels, Tab } from "@chakra-ui/react";
import { InputGroup, Input, InputRightElement, Button } from "@chakra-ui/react";
import { Heading, Code } from "evergreen-ui";
import { toaster } from "evergreen-ui";
import { Pane } from "evergreen-ui";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "evergreen-ui";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import { DEFAULT_META_KEYWORDS } from "../../App";
import { getWebsiteName } from "../../Helpers/HelperFunctions";
import { Box } from "../About/About";
import { hexToCmyk, hexToRGB, hexToHSL, rgbToHex, rgbToHsl, rgbToCmyk } from "../../Constants/ColorFunctions";
import { ColorSquare } from "../About/About";
import { PaletteWrapper } from "../ColorPalette/RandomColorPalette";
import { SecondaryPaletteWrapper } from "../../Components/ColorPicker/ColorPicker";

//  INFORMATION
import HexToRgb from "./Information/HEX/HexToRgb";
import HexToHsl from "./Information/HEX/HexToHsl";
import HexToCmyk from "./Information/HEX/HexToCymk";

//  COLOR PALETTES
import PaletteData from "../../Constants/Palettes.json"
import SmallColorPalette from "../ColorPalette/SmallColorPalette";
import RgbToHex from "./Information/RGB/RgbToHex";
import RgbToHsl from "./Information/RGB/RgbToHsl";
import RgbToCymk from "./Information/RGB/RgbToCymk";
import { META_TITLE_ADDITION } from "../../Constants/Constants";

export function validateHexCode(hexCode) {
    // Check if the string matches the hex color pattern
    const hexPattern = /^[0-9A-Fa-f]{6}$/;
    const hexPattern2 = /^#[0-9A-Fa-f]{6}$/;
    
    if (hexPattern.test(hexCode) || hexPattern2.test(hexCode)) {
        // If it's a valid hex code, add '#' if missing
        if (hexCode[0] !== '#') {
            hexCode = '#' + hexCode;
        }
        return hexCode;
    } else {
        return null; // Invalid hex code
    }
}


export default function Convert(props) {

    const defaultColorCode = (props.original === 'HEX' ? '#df95ff' : '223, 149, 255');
    const colorScheme = (props.original === 'HEX' ? 'orange' : 'blue')
    const firstTabName = (props.original === 'HEX' ? 'RGB' : 'HEX')
    const [code, setCode] = React.useState(props.original === 'HEX' ? '#df95ff' : '223, 149, 255');
    const [title, setTitle] = React.useState();
    const [hexValue, setHexValue] = React.useState(props.original === 'HEX' ? hexToRGB('#df95ff') : rgbToHex('223, 149, 255'));
    const [rgbValue, setRgbValue] = React.useState(props.original === 'HEX' ? hexToRGB('#df95ff') : rgbToHex('223, 149, 255'));
    const [hslValue, setHslValue] = React.useState(props.original === 'HEX' ? hexToHSL('#df95ff') : rgbToHsl('223, 149, 255'));
    const [cymkValue, setCymkValue] = React.useState(props.original === 'HEX' ? hexToCmyk('#df95ff') : rgbToCmyk('223, 149, 255'));

    return (
        <>
        <Helmet>
            <title>{`${props.original} to ${props.title} - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
            <meta
                name="description"
                content={"Convert your HEX or RGB color codes into many different tech related color variables such as UIColor for Swift/Objective-C and Java"}
            />
            <meta
                name="keywords"
                content={DEFAULT_META_KEYWORDS}
            />
        </Helmet>
        <div style={{textAlign: 'center'}}>
        <Heading size={900} style={{marginBottom: '20px'}}>Convert a <Code size={900} appearance="minimal">{props.original}</Code> Color to...</Heading>
        <Tabs variant='soft-rounded' colorScheme={colorScheme} align="center" defaultIndex={props.index}>
            <TabList>
                <Tab>{firstTabName}</Tab>
                <Tab>HSL</Tab>
                <Tab>CYMK</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <Box>
                        {props.original === 'HEX' ? null : 
                            <Heading size={400} style={{marginBottom: '15px'}}>Input the RGB color code in the following format: <Code size={300}>255, 255, 255</Code></Heading>
                        }
                        <InputGroup size='lg'>
                            <Input
                                pr='4.5rem'
                                type={'text'}
                                placeholder={`Enter ${props.original} Code`}
                                onChange={(e) => {
                                    if (props.original === 'HEX') {
                                        setCode(e.target.value)
                                        if (hexToRGB(e.target.value) === null) {
                                            toaster.warning('Invalid HEX Value', {
                                                id: 'forbidden'
                                            })

                                            setHslValue(hexToHSL(defaultColorCode))
                                            setCymkValue(hexToCmyk(defaultColorCode))
                                        } else {
                                            setRgbValue(hexToRGB(e.target.value))
                                            setHslValue(hexToHSL(e.target.value))
                                            setCymkValue(hexToCmyk(e.target.value))
                                        }
                                    } else {
                                        setCode(e.target.value)
                                        if (rgbToHex(e.target.value) === null) {
                                            toaster.warning('Invalid RGB Value', {
                                                id: 'forbidden'
                                            })
                                            setHslValue(rgbToHsl(defaultColorCode))
                                            setCymkValue(rgbToCmyk(defaultColorCode))
                                        } else {
                                            setHexValue(rgbToHex(e.target.value))
                                            setHslValue(rgbToHsl(e.target.value))
                                            setCymkValue(rgbToCmyk(e.target.value))
                                        }
                                    }
                                }}
                                value={code}
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='2.5rem' size='sm' 
                                style={{
                                    marginRight: '10px'
                                }}>
                                {'Convert'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Pane>
                            {props.original === 'HEX' ? 
                                <>
                                    <Heading size={900}>{"RGB:"}</Heading>
                                    <Button style={{padding: 0}} appearance="minimal" onClick={() => {
                                        navigator.clipboard.writeText(rgbValue)
                                        toaster.success('Copied to clipboard')} }>
                                            <Heading size={900}>{rgbValue}</Heading>
                                    </Button>
                                </>
                             :
                                <>
                                    <Heading size={900}>{"HEX:"}</Heading>
                                    <Button style={{padding: 0}} appearance="minimal" onClick={() => {
                                        navigator.clipboard.writeText(rgbToHex(code))
                                        toaster.success('Copied to clipboard')} }>
                                            <Heading size={900}>{rgbToHex(code)}</Heading>
                                    </Button>
                                </>
                            }
                            {props.original === 'HEX' ? validateHexCode(code) ? 
                            <Link to={`/hex/${code.replace('#','')}`}><ColorSquare style={{backgroundColor: validateHexCode(code), width: '200px', height: '200px', margin: 'auto', marginTop: '30px'}} /></Link> :
                            <br /> : validateHexCode(rgbToHex(code)) ? 
                            <Link to={`/hex/${rgbToHex(code).replace('#','')}`}><ColorSquare style={{backgroundColor: validateHexCode(rgbToHex(code)), width: '200px', height: '200px', margin: 'auto', marginTop: '30px'}} /></Link> :
                            <br />
                            }
                        </Pane>
                    </Box>
                    <Box style={{marginTop: '20px'}}>
                        {props.original === 'HEX' ? <HexToRgb /> : <RgbToHex />}
                    </Box>
                </TabPanel>
                <TabPanel>
                    <Box>
                    {props.original === 'HEX' ? null : 
                        <Heading size={400} style={{marginBottom: '15px'}}>Input the RGB color code in the following format: <Code size={300}>255, 255, 255</Code></Heading>
                    }
                        <InputGroup size='lg'>
                            <Input
                                pr='4.5rem'
                                type={'text'}
                                placeholder={`Enter ${props.original} Code`}
                                onChange={(e) => {
                                    if (props.original === 'HEX') {
                                        setCode(e.target.value)
                                        if (hexToHSL(e.target.value) === null) {
                                            toaster.warning('Invalid HEX Value', {
                                                id: 'forbidden'
                                            })

                                            setRgbValue(defaultColorCode)
                                            setHslValue(hexToHSL(defaultColorCode))
                                            setCymkValue(hexToCmyk(defaultColorCode))
                                        } else {
                                            setRgbValue(hexToRGB(e.target.value))
                                            setHslValue(hexToHSL(e.target.value))
                                            setCymkValue(hexToCmyk(e.target.value))
                                        }
                                    } else {
                                        setCode(e.target.value)
                                        if (rgbToHsl(e.target.value) === null) {
                                            toaster.warning('Invalid RGB Value', {
                                                id: 'forbidden'
                                            })
                                            setHexValue(rgbToHex(defaultColorCode))
                                            setHslValue(rgbToHsl(defaultColorCode))
                                            setCymkValue(rgbToCmyk(defaultColorCode))
                                        } else {
                                            setHexValue(rgbToHex(e.target.value))
                                            setHslValue(rgbToHsl(e.target.value))
                                            setCymkValue(rgbToCmyk(e.target.value))
                                        }
                                    }
                                }}
                                value={code}
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='2.5rem' size='sm' style={{
                                    marginRight: '10px'
                                }}>
                                {'Convert'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Pane>
                            <Heading size={900}>{"HSL:"}</Heading>
                            <Button style={{padding: 0}} appearance="minimal" onClick={() => {
                                navigator.clipboard.writeText(hslValue)
                                toaster.success('Copied to clipboard')} }>
                                    <Heading size={900}>{hslValue}</Heading>
                            </Button>
                            {props.original === 'HEX' ? validateHexCode(code) ? 
                            <Link to={`/hex/${code.replace('#','')}`}><ColorSquare style={{backgroundColor: validateHexCode(code), width: '200px', height: '200px', margin: 'auto', marginTop: '30px'}} /></Link> :
                            <br /> : validateHexCode(rgbToHex(code)) ? 
                            <Link to={`/hex/${rgbToHex(code).replace('#','')}`}><ColorSquare style={{backgroundColor: validateHexCode(rgbToHex(code)), width: '200px', height: '200px', margin: 'auto', marginTop: '30px'}} /></Link> :
                            <br />
                            }
                        </Pane>
                    </Box>
                    <Box style={{marginTop: '20px'}}>
                        {props.original === 'HEX' ? <HexToHsl /> : <RgbToHsl />}
                    </Box>
                </TabPanel>
                <TabPanel>
                    <Box>
                    {props.original === 'HEX' ? null : 
                        <Heading size={400} style={{marginBottom: '15px'}}>Input the RGB color code in the following format: <Code size={300}>255, 255, 255</Code></Heading>
                    }
                        <InputGroup size='lg'>
                            <Input
                                pr='4.5rem'
                                type={'text'}
                                placeholder={`Enter ${props.original} Code`}
                                onChange={(e) => {
                                    if (props.original === 'HEX') {
                                        setCode(e.target.value)
                                        if (hexToCmyk(e.target.value) === null) {
                                            toaster.warning('Invalid HEX Value', {
                                                id: 'forbidden'
                                            })

                                            setRgbValue(defaultColorCode)
                                            setHslValue(hexToHSL(defaultColorCode))
                                            setCymkValue(hexToCmyk(defaultColorCode))
                                        } else {
                                            setRgbValue(hexToRGB(e.target.value))
                                            setHslValue(hexToHSL(e.target.value))
                                            setCymkValue(hexToCmyk(e.target.value))
                                        }
                                    } else {
                                        setCode(e.target.value)
                                        if (rgbToCmyk(e.target.value) === null) {
                                            toaster.warning('Invalid RGB Value', {
                                                id: 'forbidden'
                                            })
                                            setHexValue(rgbToHex(defaultColorCode))
                                            setHslValue(rgbToHsl(defaultColorCode))
                                            setCymkValue(rgbToCmyk(defaultColorCode))
                                        } else {
                                            setHexValue(rgbToHex(e.target.value))
                                            setHslValue(rgbToHsl(e.target.value))
                                            setCymkValue(rgbToCmyk(e.target.value))
                                        }
                                    }
                                }}
                                value={code}
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='2.5rem' size='sm' style={{
                                    marginRight: '10px'
                                }}>
                                {'Convert'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Pane>
                            <Heading size={900}>{"CYMK:"}</Heading>
                            <Button style={{padding: 0}} appearance="minimal" onClick={() => {
                                navigator.clipboard.writeText(cymkValue)
                                toaster.success('Copied to clipboard')} }>
                                    <Heading size={900}>{cymkValue}</Heading>
                            </Button>
                            {props.original === 'HEX' ? validateHexCode(code) ? 
                            <Link to={`/hex/${code.replace('#','')}`}><ColorSquare style={{backgroundColor: validateHexCode(code), width: '200px', height: '200px', margin: 'auto', marginTop: '30px'}} /></Link> :
                            <br /> : validateHexCode(rgbToHex(code)) ? 
                            <Link to={`/hex/${rgbToHex(code).replace('#','')}`}><ColorSquare style={{backgroundColor: validateHexCode(rgbToHex(code)), width: '200px', height: '200px', margin: 'auto', marginTop: '30px'}} /></Link> :
                            <br />
                            }
                        </Pane>
                    </Box>
                    <Box style={{marginTop: '20px'}}>
                        {props.original === 'HEX' ? <HexToCmyk /> : <RgbToCymk />}
                    </Box>
                </TabPanel>
            </TabPanels>
        </Tabs>
        <SecondaryPaletteWrapper>
            {PaletteData.map(function(obj, i) {
                if (i >= 10) { return null }
                return (
                    <PaletteWrapper>
                        <SmallColorPalette palette={obj.colors} title={obj.name} desc={obj.description} style={{display: 'inline-block'}} />
                    </PaletteWrapper>
                )
            })}
        </SecondaryPaletteWrapper>
        <Button marginY={8}  iconAfter={ArrowRightIcon} appearance="primary" style={{float: 'right', marginRight: 20}}>
            View More Palettes
        </Button>
    </div>
    </>
    );
}
