import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'

import HamburgerMenu from './Components/Navbar/HamburgerMenu';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';

//  Analytics
import ReactGA from "react-ga4";
import { Analytics } from '@vercel/analytics/react';

ReactGA.initialize([
    {
      trackingId: "G-SERM57V93H", // csscolors.net
    },
    {
      trackingId: "G-Z0S4DGPJ56", // htmlcolors.co
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ChakraProvider>
      <Analytics />
      {window.innerWidth <= 800 ? <HamburgerMenu /> : <Navbar />}
        <App/>
      <Footer />
    </ChakraProvider>
  </BrowserRouter>
);