import { Heading } from "evergreen-ui";
import { Box } from "../../../About/About";
import { Code } from "@chakra-ui/react";
import PrimaryColors from "../PrimaryColors";

export default function RgbToHex() {
    return (
        <div style={{textAlign: 'left'}}>
            <Heading size={800}>Convert RGB to HEX: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Convert each of the red, green, and blue components to a hexadecimal value using a decimal to HEX converter.<br/>
            2. Concatenate the three hexadecimal values, starting with the red value, to form the HEX color code.<br/>
            </p>
            <p style={{marginTop: '10px'}}>
            For example, the RGB color code <Code size={300} appearance="minimal">(255, 0, 0)</Code> represents the color red. To convert this to HEX, we would first convert each of the red, green, and blue components to a hexadecimal value:
            <br />
            <div style={{textAlign: 'center'}}>
                <Code size={300} appearance="minimal">255 = FF</Code>, <Code size={300} appearance="minimal">0 = 00</Code>, and <Code size={300} appearance="minimal">0 = 00</Code>
            </div>
            <br />
            <Box style={{textAlign: 'center'}}>
                <Code style={{fontSize: '40px'}} variant={'solid'} colorScheme="blue">FF</Code> <Code style={{fontSize: '40px'}} variant={'solid'}  colorScheme="red">00</Code> <Code style={{fontSize: '40px'}} variant={'solid'}  colorScheme="green">00</Code> 
            </Box>
            <br />
            Then, we would concatenate the three hexadecimal values to get the HEX color code: <Code size={300} appearance="minimal">#FF0000</Code>.
            <br />
            <br />
            <Heading size={800}  style={{marginBottom: '10px'}}>Primary Colors</Heading>
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
    );
}