import { Heading } from "evergreen-ui";
import PrimaryColors from "../PrimaryColors";
import { Code } from "@chakra-ui/react";

export default function RgbToCymk() {
    return (
        <div style={{textAlign: 'left'}}>
            <Heading size={800}>Convert RGB to CYMK: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Divide the red, green, and blue components by 255 to normalize the values to a range of 0 to 1.<br/>
            2. Calculate the cyan (C), magenta (M), yellow (Y), and black (K) values using the following formulas:
            <br />
            <br />
            <Code size={500}>
                C = 1 - R<br />
                M = 1 - G<br />
                Y = 1 - B<br />
                K = min(C, M, Y)
            </Code>
            3. The cyan, magenta, yellow, and black values are represented as percentages.
            </p>
            <p style={{marginTop: '10px'}}>
            For example, the RGB color code <Code size={300} appearance="minimal">(255, 0, 0)</Code> represents the color red. To convert this to CMYK, we would first normalize the red, green, and blue components: <Code size={300} appearance="minimal">(1, 0, 0)</Code>. Then, we would calculate the cyan, magenta, yellow, and black values using the formulas above:
            <br />
            <br />
            <Code size={500}>
                C = 1 - 1 = 0%<br />
                M = 1 - 0 = 100%<br />
                Y = 1 - 0 = 100%<br />
                K = min(0, 100, 100) = 0%
            </Code>
            <br />
            <br />
            Therefore, the CMYK color code for the RGB color code <Code size={300} appearance="minimal">(255, 0, 0)</Code> is <Code size={300} appearance="minimal">(0%, 100%, 100%, 0%)</Code>.
            <br />
            <br />
            <Heading size={700} style={{marginBottom: '10px'}}>Extra Fun</Heading>
            It is important to note that the CMYK color model is a subtractive color model, meaning that colors are created by subtracting light from white. This is in contrast to the RGB color model, which is an additive color model, meaning that colors are created by adding light together.
            <br />
            <br />
            The CMYK color model is typically used in print production, as it allows for a wider range of colors to be printed than the RGB color model. However, it is important to note that not all colors can be perfectly reproduced in CMYK, so it is important to test your colors before printing.
            <br />
            <br />
            <Heading size={800}  style={{marginBottom: '10px'}}>Primary Colors</Heading>
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
    );
}