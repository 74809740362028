import React from 'react';
import { Button } from 'evergreen-ui';
import { slide as Menu } from 'react-burger-menu'
import { NavLink } from 'react-router-dom';
import { Heading } from 'evergreen-ui';

import { getRandomHexColor } from '../../Constants/ColorFunctions';

export default class HamburgerMenu extends React.Component {
  render () {
    return (
        <Menu right styles={styles} width={230}>
            <Button style={{display: 'none', margin: 0, padding: 0}}></Button>
            <Button marginLeft={16} marginBottom={20} appearance="minimal" style={{color: 'white', borderWidth: 0}}>
                    <NavLink reloadDocument to='/' style={{color: 'white', fontSize: 15}}>
                    Home
                    </NavLink>
            </Button>
        <Heading style={{marginTop: '10px', color: 'white'}} size={400}>Convert</Heading>
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>
            <NavLink reloadDocument to="/hex-to/rgb">HEX to RGB</NavLink>       
        </Button>
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>
        <NavLink reloadDocument to="/hex-to/cymk">HEX to CYMK</NavLink>
        </Button>
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>
        <NavLink reloadDocument to="/hex-to/hsl">HEX to HSL</NavLink>  
        </Button>
        <br />
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>            
        <NavLink reloadDocument to="/rgb-to/hex">RGB to HEX</NavLink>  
        </Button>  
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>           
        <NavLink reloadDocument to="/rgb-to/hsl">RGB to HSL</NavLink>
        </Button>
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>
        <NavLink reloadDocument to="/rgb-to/cymk">RGB to CYMK</NavLink>     
        </Button>   
        <br />
        <Heading style={{marginTop: '10px', color: 'white'}} size={400}>Convert to Tech</Heading>
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>
            <NavLink reloadDocument to="/hex-to/uicolor">HEX to UIColor</NavLink>      
        </Button>
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>
            <NavLink reloadDocument to="/hex-to/java">HEX to Java</NavLink>
        </Button>
        <br />
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>            
            <NavLink reloadDocument to="/rgb-to/uicolor">RGB to UIColor</NavLink> 
        </Button>  
        <Button marginLeft={16} appearance="minimal" style={{color: 'white'}}>           
            <NavLink reloadDocument to="/rgb-to/java">RGB to Java</NavLink>
        </Button>
        <br />
        <Button marginLeft={-15} marginBottom={10} appearance="minimal" style={{color: 'white'}}>
            <NavLink reloadDocument to={`/color-names`} style={{fontSize: 15}} activeStyle>
            Color Names
            </NavLink>
        </Button>
        <Button marginLeft={-15} marginBottom={10} appearance="minimal" style={{color: 'white'}}>
            <NavLink reloadDocument to={`/color-palettes`} style={{fontSize: 15}} activeStyle>
            Color Palettes
            </NavLink>
        </Button>
        <Button marginLeft={-15} marginBottom={10} appearance="minimal" style={{color: 'white'}}>
            <NavLink reloadDocument to={`/hex/${getRandomHexColor().replace('#', '')}`} style={{fontSize: 15}} activeStyle>
            Random Color
            </NavLink>
        </Button>
        <Button marginLeft={-15} marginBottom={10} appearance="minimal" style={{color: 'white'}}>
            <NavLink reloadDocument to={`/color-palette/random`} style={{fontSize: 15}} activeStyle>
            Random Color Palette
            </NavLink>
        </Button>
        <br/>
        <br/>
        <br/>
        <br/>
        </Menu>
    );
  }
}

var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      right: '36px',
      top: '36px'
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em'
    },
    bmItem: {
      display: 'flex'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }
  