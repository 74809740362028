import styled from "styled-components";

import { IconButton } from "evergreen-ui";
import { ArrowRightIcon } from "evergreen-ui";
import { Link } from "react-router-dom";

function SmallColorPalette(props) {
    //   border-radius: 20px 20px 0px 0px;

  return (  
    <ColorContent style={{backgroundColor: '#F1F1F1'}}>
        <Hover>
        <Color style={{backgroundColor: props.palette[0], borderRadius: '5px 0px 0px 0px'}} />
            <Color style={{backgroundColor: props.palette[1]}} />
            <Color style={{backgroundColor: props.palette[2]}} />
            <Color style={{backgroundColor: props.palette[3], borderRadius: '0px 5px 0px 0px'}} />
            <div style={{display: 'flex', paddingRight: '20px'}}>
                <SubHeader>{props.title}</SubHeader>
                <Link reloadDocument to={`/color-palette/${props.title}`}><IconButton icon={ArrowRightIcon} appearance="minimal" /></Link>
            </div>
            <RegText>{props.desc}</RegText>
        </Hover>
    </ColorContent>
  );
}

export default SmallColorPalette;

const ColorContent = styled.div`
   width: 400px;
   height: 300px;
   border-radius: 5px;
`;

const Color = styled.div`
   width: 100px;
   height: 70%;
   display: inline-block;
   margin-bottom: 20px;
`;

const SubHeader = styled.h3`
    margin: auto;
    text-align: left;
    margin-left: 40px;
    font-size: 20px;
    font-weight: bold;
`

const RegText = styled.h3`
    margin: auto;
    margin-top: 5px;
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 15px;
    opacity: 0; 
`

const Hover = styled.h3`
height: 90%;

transition: 0.7s all ease;

&:hover {
    transform: translateY(-70px);
}

&:hover ${RegText} {
    transition: 2s;
    opacity: 1;
}
`