import { HexColorPicker } from "react-colorful";
import { Heading, Table, TextInput, Button } from "evergreen-ui";
import { Text, Code } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "evergreen-ui";
import { Component } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { getWebsiteName } from "../../Helpers/HelperFunctions";
import { DEFAULT_META_KEYWORDS, DEFAULT_META_DESCRIPTION } from "../../App";
import { META_TITLE_ADDITION, WEBSITE_NAME } from "../../Constants/Constants";
import { ColorSquare } from "../../Pages/About/About";
import SmallColorPalette from "../../Pages/ColorPalette/SmallColorPalette";
import { Content } from "../../Pages/Home/Home";
import PaletteData from "../../Constants/Palettes.json"
import { hexToCSS, hexToHSL, convertHexToAndroidColor, hexToUIColorSwift, hexToHsv, hexToCmyk, getRandomHexColor, hexToUIColorObjC } from "../../Constants/ColorFunctions";
import HexToRgb from "../../Pages/Convert/Information/HEX/HexToRgb";
import { Box } from "../../Pages/About/About";
import { PaletteWrapper } from "../../Pages/ColorPalette/RandomColorPalette";
import HexToUicolor from "../../Pages/Convert/Information/HEXTECH/HexToUicolor";
import { copyToClipBoard } from "../../Helpers/HelperFunctions";

export const hexToRGB = (hexColor) => {
    hexColor = hexColor.replace('#', '');
    const numericValue = parseInt(hexColor, 16);
    const r = numericValue >> 16 & 0xFF;
    const g = numericValue >> 8 & 0xFF;
    const b = numericValue & 0xFF;
    return `${r}, ${g}, ${b}`
  }

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: getRandomHexColor(),
        }
      };
    
      render() {
        return (
            <>
            <Helmet>
                <title>{`Home - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
                <meta
                    name="description"
                    content={DEFAULT_META_DESCRIPTION}
                />
                <meta
                    name="keywords"
                    content={DEFAULT_META_KEYWORDS}
                />
            </Helmet>
            <Content 
            style={{backgroundColor: 'white', borderWidth: '0.1px', borderColor: 'gray', borderStyle: 'none solid'}}>
                <ContentWrapper>
                    <div style={{position: 'relative', height: '100%'}}>
                        <CodeWrapper size={900} colorScheme="blue">{WEBSITE_NAME}</CodeWrapper>
                        <Heading size={900} marginTop={20} marginBottom={20}>
    
                            <HeadingWrapper>
                                <ColorSpan>RGB: </ColorSpan><Button appearance="minimal" onClick={() => {copyToClipBoard(hexToRGB(this.state.color))} }><Heading size={900}>{hexToRGB(this.state.color)}</Heading></Button>
                                <MobileBreak />
                                <ColorSpan>HEX: </ColorSpan><Button appearance="minimal" onClick={() => {copyToClipBoard(this.state.color)}}><Heading size={900}>{this.state.color.toUpperCase()}</Heading></Button>
                                <MobileBreak />
                                <ColorSpan>HSL: </ColorSpan><Button appearance="minimal" onClick={() => {copyToClipBoard(hexToHSL(this.state.color))}}><Heading size={900}>{hexToHSL(this.state.color)}</Heading></Button>
                                
                                <MobileBreak />
                                <ColorSpan>COLOR: <Link to={`/hex/${this.state.color.replace('#','')}`}><Button style={{width: '70px', height: '70px', padding: 0}} appearance="minimal"><div style={{width: '100%', height: '100%', backgroundColor: this.state.color, borderRadius: '3px'}} /></Button></Link></ColorSpan>
                            </HeadingWrapper>
                            <ButtonWrapper>
                                <br />
                                <Link to={`/hex/${this.state.color.replace('#','')}`}>
                                    <Button>
                                        View Color Details →
                                    </Button>
                                </Link>
                            </ButtonWrapper>
                        </Heading>
                        <br />
                        <hr />
                        <br />

                        <ColorSelectorWrapper>
                            <div style={{margin: 'auto'}}>
                                <HexColorPickerWrapper>
                                    <HexColorPicker color={this.state.color} onChange={e => {this.setState({color: e})}} style={{height: '100%', width: '100%'}} />
                                </HexColorPickerWrapper>

                                <TextInput onChange={e => {this.setState({color: e.target.value})}} value={this.state.color.toUpperCase()} style={{marginTop: '5px'}} />
                            </div>
                            <TableWrapper>
                                <Table.Body height={340} width={'auto'}>
                                <Table.Row key={"HSV"} isSelectable onSelect={() => {copyToClipBoard(hexToHsv(this.state.color))}}>
                                        <Table.TextCell>{"HSV"}</Table.TextCell>
                                        <Table.TextCell flexBasis={0} flexShrink={0} flexGrow={10}><Code size={100}>{hexToHsv(this.state.color)}</Code></Table.TextCell>
                                </Table.Row>
                                <Table.Row key={"CMYK"} isSelectable onSelect={() => {copyToClipBoard(hexToCmyk(this.state.color))}}>
                                        <Table.TextCell>{"CMYK"}</Table.TextCell>
                                        <Table.TextCell flexBasis={0} flexShrink={0} flexGrow={10}><Code size={100}>{hexToCmyk(this.state.color)}</Code></Table.TextCell>
                                </Table.Row>
                                <Table.Row key={"CSS"} isSelectable onSelect={() => {copyToClipBoard(hexToCSS(this.state.color))}}>
                                        <Table.TextCell>{"CSS"}</Table.TextCell>
                                        <Table.TextCell flexBasis={0} flexShrink={0} flexGrow={10}><Code size={100}>{hexToCSS(this.state.color.toUpperCase())}</Code></Table.TextCell>
                                </Table.Row>
                                <Table.Row key={"Swift"} isSelectable onSelect={() => {copyToClipBoard(HexToUicolor(this.state.color))} }>
                                    <Table.TextCell>{"Swift"}</Table.TextCell>
                                    <Table.TextCell flexBasis={0} flexShrink={0} flexGrow={10}><Code size={100}>{hexToUIColorSwift(this.state.color)}</Code></Table.TextCell>
                                </Table.Row>
                                <Table.Row key={"Objective-C"} isSelectable onSelect={() => {copyToClipBoard(hexToUIColorObjC(this.state.color))}}>
                                    <Table.TextCell>{"Objective-C"}</Table.TextCell>
                                    <Table.TextCell flexBasis={0} flexShrink={0} flexGrow={4}><Code size={100}>{hexToUIColorObjC(this.state.color)}</Code></Table.TextCell>
                                </Table.Row>
                                <Table.Row key={"Java"} isSelectable onSelect={() => {copyToClipBoard(convertHexToAndroidColor(this.state.color))}}>
                                    <Table.TextCell>{"Java"}</Table.TextCell>
                                    <Table.TextCell flexBasis={0} flexShrink={0} flexGrow={10}><Code size={100}>{convertHexToAndroidColor(this.state.color)}</Code></Table.TextCell>
                                </Table.Row>
                                <Table.Row key={"Kotlin"} isSelectable onSelect={() => {copyToClipBoard(convertHexToAndroidColor(this.state.color))}}>
                                    <Table.TextCell>{"Kotlin"}</Table.TextCell>
                                    <Table.TextCell flexBasis={0} flexShrink={0} flexGrow={10}><Code size={100}>{`Color.parseColor("${this.state.color}")`}</Code></Table.TextCell>
                                </Table.Row>
                                </Table.Body>
                            </TableWrapper>
                        </ColorSelectorWrapper>
                    </div>
    
                    <SecondaryPaletteWrapper>
                    {PaletteData.map(function(obj, i) {
                        if (i >= 10) { return null }
                        return (
                            <PaletteWrapper>
                                <SmallColorPalette palette={obj.colors} title={obj.name} desc={obj.description} style={{display: 'inline-block'}} />
                            </PaletteWrapper>
                        )
                    })}
                    </SecondaryPaletteWrapper>
                    <Button marginY={8} marginTop={20} iconAfter={ArrowRightIcon} appearance="primary" style={{float: 'right'}}>
                        View More Palettes
                    </Button>
    
                    <Box style={{marginTop: '100px', marginBottom: '50px'}}>
                        <Heading size={900}>
                            How to Pick the Right Color
                        </Heading>
                        <div style={{display: 'flex', marginTop: '10px'}}>
                            <ColorSquare style={{backgroundColor: '#89CFF0', marginRight: '10px'}} />
                            <ColorSquare style={{backgroundColor: '#0096FF', marginRight: '10px'}} />
                            <ColorSquare style={{backgroundColor: '#0437F2', marginRight: '10px'}} />
                            <ColorSquare style={{backgroundColor: '#0818A8'}} />
                        </div>
                        <Text style={{marginTop: '10px', fontSize: '20px'}}>
    Selecting the right colors for a project is more important than one might think. </ Text><Text as={'mark'} style={{fontSize: '20px'}}>Colors have a unique ability to convey feelings and messages,</Text><Text style={{fontSize: '20px', display: 'flex'}}> creating a certain atmosphere or mood. They can greatly affect how people perceive and interact with a design, whether it's a website, a room, or a product. The right colors can make information easy to read and understand, and they can even influence our decisions when it comes to purchasing products or engaging with content. <br /><br /> Right colors help establish a recognizable identity, setting a project apart from competitors. So, when it comes to design, choosing the right colors is like choosing the right words in a conversation - they can make all the difference in how the message is received and remembered.</Text>
                    </Box>
                </ContentWrapper>
                <Box style={{marginBottom: '20px'}}>
                    <HexToRgb />
                </Box>
            </Content>
            </>
        );
      }
}


const ColorSelectorWrapper = styled.div`
    display: flex;

    @media (max-width: 1200px) {
        display: inline-block;
    }  
`

const ContentWrapper = styled.div`
    margin-left: 50px;
    margin-right: 50px;

    @media (max-width: 800px) {
        margin-left: 2px;
        margin-right: 2px;
    }  
`

export const SecondaryPaletteWrapper = styled.div`
    margin-top: 50px;
    width: 100%;
    text-align: center;

    @media (max-width: 800px) {
    }  
`

const TableWrapper = styled(Table)`
    margin: auto;
    width: 500px;
    margin-top: 0px;

    @media (max-width: 800px) {
        margin: auto;
        width: 400px;
        margin-top: 20px;
    }  
`

const HeadingWrapper = styled.span`
    font-size: 20px;

    @media (max-width: 800px) {
        display: inline-block;
    }  
`

const MobileBreak = styled.br`
    display: none;

    @media (max-width: 1200px) {
        display: block;
    }
`

const ColorSpan = styled.span`
    color: gray;

    @media (max-width: 800px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`

const CodeWrapper = styled(Code)`
    @media (max-width: 800px) {
        margin-left: 20px;
    }
`

const HexColorPickerWrapper = styled.div`
    width: 400px;
    height: 300px;

    @media (max-width: 800px) {
        margin-left: 5px;
    }
`

const ButtonWrapper = styled.div`
    @media (max-width: 800px) {
        margin-left: 20px;
    }
`