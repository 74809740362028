import { Heading } from "evergreen-ui";
import { Code } from "@chakra-ui/react";
import PrimaryColors from "../PrimaryColors";

export default function RgbToUicolor() {
    return (
        <div style={{textAlign: 'left'}}>
            <Heading size={800}>Convert RGB to UIColor: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Divide the red, green, and blue components by 255 to normalize the values to a range of 0 to 1.<br/>
            2. Create a UIColor object using the normalized red, green, and blue values.<br/>
            <br />
            </p>
            <p style={{marginTop: '10px'}}>
            For example, to convert the RGB color code <Code size={300} appearance="minimal">(255, 0, 0)</Code> to a UIColor, we would first normalize the red, green, and blue values: <Code size={300} appearance="minimal">(1, 0, 0)</Code>. Then, we would create a UIColor object using the normalized red, green, and blue values:<br />
            <br />
            To create a <Code size={300} appearance="minimal">UIColor</Code> object using the normalized red, green, and blue values, we can use the following code:
            <br />
            <br />
            <Code size={500}>
                let red = CGFloat(1.0)<br />
                let green = CGFloat(0.0)<br />
                let blue = CGFloat(0.0)<br />
                let color = UIColor(red: red, green: green, blue: blue, alpha: 1.0)
            </Code>
            <br />
            <br />
            The <Code size={300} appearance="minimal">color</Code> variable will now contain a <Code size={300} appearance="minimal">UIColor</Code> object that represents the color red.<br/><br/>
            <Heading size={800}>UIColor Extension</Heading>
            <br />
            You can also use a variety of Swift extensions to convert RGB color codes to UIColor objects. For example, the following extension provides a convenience initializer for creating UIColor objects from RGB color values:            <br />
            <br />
            <Code 
                style={{width: '100%'}}
                size={500}
                whiteSpace={'pre'}
                children={`
extension UIColor {
    convenience init(red: CGFloat, green: CGFloat, 
        blue: CGFloat, alpha: CGFloat = 1.0) {
        self.init(red: red, green: green, blue: blue, alpha: alpha)
    }
}
            `}
            />
            <br />
            <br />
            To use this extension, you can simply pass the red, green, and blue components to the <Code size={300} appearance="minimal">UIColor(red:green:blue:alpha:)</Code> initializer. For example, the following code will create a UIColor object that represents the color red:            <br />
            <br />
            <Code size={500} appearance={'minimal'}>let color = UIColor(red: 1.0, green: 0.0, blue: 0.0)</Code>
            <br />
            <br />
            <Heading size={800}>Primary Colors</Heading>
            <br />
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
    );
}