import { useEffect } from "react";
import { extendTheme } from "@chakra-ui/react";
import styled from "styled-components";
import { Heading, ArrowRightIcon, Button } from "evergreen-ui";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import { DEFAULT_META_KEYWORDS } from "../../App";
import { Content } from "../Home/Home";
import HexToRgb from "../Convert/Information/HEX/HexToRgb";
import { getWebsiteName } from "../../Helpers/HelperFunctions";
import Names from "./Names";
import { PaletteWrapper } from "../ColorPalette/RandomColorPalette";
import { SecondaryPaletteWrapper } from "../../Components/ColorPicker/ColorPicker";
import SmallColorPalette from "../../Pages/ColorPalette/SmallColorPalette";
import PaletteData from "../../Constants/Palettes.json"
import { META_TITLE_ADDITION } from "../../Constants/Constants";

export function generateMonochromaticColors(hexColor) {
  // Convert HEX to RGB
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate the luminance (brightness) of the color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  const step = luminance > 0.5 ? -30 : 30; // Adjust this value to change the intensity of the colors
  const numShades = 8; // Adjust this value to get more or fewer shades

  const shades = [];

  for (let i = 0; i < numShades; i++) {
    const newR = r + i * step;
    const newG = g + i * step;
    const newB = b + i * step;

    // Ensure the values are within the valid range (0-255)
    const finalR = Math.min(Math.max(newR, 0), 255);
    const finalG = Math.min(Math.max(newG, 0), 255);
    const finalB = Math.min(Math.max(newB, 0), 255);

    // Convert RGB back to HEX
    const newHexColor = `#${finalR.toString(16).padStart(2, '0')}${finalG.toString(16).padStart(2, '0')}${finalB.toString(16).padStart(2, '0')}`;
    shades.push(newHexColor);
  }

  return shades;
}



export default function ColorNames() {

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/color-names" });
    })

    return (
      <>
      <Helmet>
          <title>{`Color Names - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
          <meta
              name="description"
              content={"View our web safe and internet safe color codes stored in HEX and RGB color values. Explore all 216 web safe colors. These are a combination of red, blue, and green colors."}
          />
          <meta
              name="keywords"
              content={DEFAULT_META_KEYWORDS}
          />
      </Helmet>
        <div style={{backgroundColor: '#F0F0F0'}}>
        <Content style={{backgroundColor: 'white', borderWidth: '1px', borderColor: 'gray', borderStyle: 'none solid'}}>
            <Box style={{marginBottom: '15px'}}>
                <Heading size={900}>{'Color Names'}</Heading>
                <p style={{marginTop: '10px'}}>Having trouble deciding on a specific color? The follow colors are all available HTML colors + more! Click on the "Color Square" to view more details about the specific color. Click on the HEX color code to copy the specific color code to your clipboard!</p>
                <div style={{marginTop: '20px'}}>
                    <hr />
                    <Names />
                </div>
            </Box>
            <Box style={{marginBottom: '30px'}}>
                <HexToRgb />
            </Box>
            <SecondaryPaletteWrapper>
                {PaletteData.map(function(obj, i) {
                    if (i >= 10) { return null }
                    return (
                        <PaletteWrapper>
                            <SmallColorPalette palette={obj.colors} title={obj.name} desc={obj.description} style={{display: 'inline-block'}} />
                        </PaletteWrapper>
                    )
                })}
            </SecondaryPaletteWrapper>
            <Button marginY={8}  iconAfter={ArrowRightIcon} appearance="primary" style={{float: 'right', marginRight: 20}}>
                View More Palettes
            </Button>
        </Content>
    </div>
    </>
    );
}

export const ColorSquare = styled.div`
  height: 65px;
  width: 65px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Box = styled.div`
    border: 2px solid #F2F2F2;
    border-radius: 5px;
    padding: 20px;
    margin-left: 20px;
    margin-right: 20px;
`

export const Space = styled.div`
    height: 50px;
`



export const theme = extendTheme({
    colors: {
      brand: {
        50: '#ff0000',
        500: '#ff0000',
        900: '#ff0000',
      }
    }
  })