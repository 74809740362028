import { Heading } from "evergreen-ui";
import { Code } from "@chakra-ui/react";
import PrimaryColors from "../PrimaryColors";
import { Box } from "../../../About/About";

export default function HexToRgb() {
    return (
        <div style={{textAlign: 'left'}}>
            <Heading size={700}>Convert HEX to RGB: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Divide the HEX code into three pairs of characters, starting from the left.<br/>
            2. Convert each pair of characters to a decimal value using a HEX to decimal converter.<br/>
            3. The three decimal values represent the red, green, and blue components of the color, respectively.<br/>
            </p>
            <p style={{marginTop: '10px'}}>
            For example, the HEX color code <Code size={300} appearance="minimal">#FF0000</Code> represents the color red. To convert this to RGB, we would first divide it into three pairs of characters: 
            <br />
            <br />
            <Box style={{textAlign: 'center'}}>
                <Code style={{fontSize: '40px'}} variant={'solid'} colorScheme="blue">FF</Code> <Code style={{fontSize: '40px'}} variant={'solid'}  colorScheme="red">00</Code> <Code style={{fontSize: '40px'}} variant={'solid'}  colorScheme="green">00</Code> 
            </Box>
            <br />
            <br />
            Then, we would convert each pair of characters to a decimal value: <Code size={300} appearance="minimal">FF = 255</Code>, <Code size={300} appearance="minimal">00 = 0</Code>, and <Code size={300} appearance="minimal">00 = 0</Code>. Finally, we would combine the three decimal values to get the RGB color code: <Code size={300} appearance="minimal">(255, 0, 0)</Code>.
            <br />
            <br />
            <Heading size={800}  style={{marginBottom: '10px'}}>Primary Colors</Heading>
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
    );
}