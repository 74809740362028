import { useParams } from "react-router";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import { DEFAULT_META_KEYWORDS, DEFAULT_META_TITLE, DEFAULT_META_DESCRIPTION } from "../../App";
import { seo } from "../../Helpers/SEO";
import { Content } from "../../Pages/Home/Home";
import Convert from "../../Pages/Convert/Convert";
import { getWebsiteName } from "../../Helpers/HelperFunctions";
import { META_TITLE_ADDITION } from "../../Constants/Constants";

export default function RgbToConvert() {
    const {code} = useParams();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/rgb-to/${code}` });
    })

    var index = 0;
    switch(code.toUpperCase()) {
        case "HEX":
            index = 0
            break
        case "HSL":
            index = 1
            break
        case "CYMK":
            index = 2
            break
        default:
            break
    }

    return(
        <>
        <Helmet>
            <title>{`RGB to ${code.toUpperCase()} - ${getWebsiteName}: ${META_TITLE_ADDITION}`}</title>
            <meta
                name="description"
                content={"Convert your HEX or RGB color codes into many different tech related color variables such as UIColor for Swift/Objective-C and Java"}
            />
            <meta
                name="keywords"
                content={DEFAULT_META_KEYWORDS}
            />
        </Helmet>
        <div style={{backgroundColor: '#F0F0F0'}}>
            <Content style={{backgroundColor: 'white', borderWidth: '0.1px', borderColor: 'gray', borderStyle: 'none solid'}}>
                <div style={{margin: 'auto'}}>
                    <Convert title={code.toUpperCase()} index={index} original={"RGB"}/>
                </div>
            </Content>
        </div>
        </>
    );
}