import { Heading } from "evergreen-ui";
import { Code } from "@chakra-ui/react";
import PrimaryColors from "../PrimaryColors";

export default function HexToJava() {
    return (
        <div style={{textAlign: 'left'}}>
            <Heading size={800}>Convert HEX to Java: Ol' Fashioned Way</Heading>
            <p style={{marginTop: '10px'}}>
            1. Remove the hash symbol (#) from the HEX color code.<br/>
            2. Divide the HEX color code into three pairs of characters.<br/>
            3. Pass the integer value to the <Code size={300} appearance="minimal">Color.valueOf()</Code> method.<br />
            <br />
            The <Code size={300} appearance="minimal">Color.valueOf()</Code> method returns a Color object that represents the specified color.
            <br />
            </p>
            <p style={{marginTop: '10px'}}>
            For example, to convert the HEX color code <Code size={300} appearance="minimal">#FF0000</Code> to a Color object, we would first remove the hash symbol: <Code size={300} appearance="minimal">FF0000</Code>. Then, we would convert the HEX color code to an integer value: <Code size={300} appearance="minimal">FF0000 = 16711680</Code>. Finally, we would pass the integer value to the <Code size={300} appearance="minimal">Color.valueOf()</Code> method:
            <br />
            <br />
            To create a <Code size={300} appearance="minimal">Color</Code> object using the normalized values, we can use the following code:
            <br />
            <br />
            <Code size={500}>
                Color color = Color.valueOf(0xFF0000);
            </Code>
            <br />
            <br />
            The color variable will now contain a Color object that represents the color red.
            <br />
            <br />
            You can also use a variety of Java libraries to convert HEX color codes to Color objects. For example, the following code uses the Apache Commons Lang library to convert the HEX color code <Code size={300} appearance="minimal">#FF0000</Code> to a Color object:
            <br />
            <br />
            <Code 
                style={{width: '100%'}}
                size={500}
                whiteSpace={'pre'}
                children={`
import org.apache.commons.lang3.math.NumberUtils;
import java.awt.Color;

String hexColorCode = "#FF0000";
Color color = Color.decode(hexColorCode);
            `}
            />
            <br />
            <br />
            <Heading size={800}  style={{marginBottom: '10px'}}>Primary Colors</Heading>
            Here is a table of the HEX codes for the primary colors:
            </p>
            <PrimaryColors />
        </div>
    );
}