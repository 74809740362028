


export function hexToCmyk(hex) {
    // Convert HEX to RGB
    let r = parseInt(hex.substring(1, 3), 16) / 255;
    let g = parseInt(hex.substring(3, 5), 16) / 255;
    let b = parseInt(hex.substring(5, 7), 16) / 255;

    // Calculate CMY
    let c = 1 - r;
    let m = 1 - g;
    let y = 1 - b;

    // Calculate K
    let k = Math.min(c, m, y);

    // Adjust CMY values
    c = (c - k) / (1 - k);
    m = (m - k) / (1 - k);
    y = (y - k) / (1 - k);

    // Round values for simplicity
    c = Math.round(c * 100);
    m = Math.round(m * 100);
    y = Math.round(y * 100);
    k = Math.round(k * 100);

    return `${c}, ${m}, ${y}, ${k}`;
}


export function hexToHsv(hex) {
    // Convert HEX to RGB
    let r = parseInt(hex.substring(1, 3), 16) / 255;
    let g = parseInt(hex.substring(3, 5), 16) / 255;
    let b = parseInt(hex.substring(5, 7), 16) / 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let delta = max - min;
    let h, s, v;

    // Calculate Hue (H)
    if (delta === 0) {
        h = 0;
    } else if (max === r) {
        h = 60 * (((g - b) / delta) % 6);
    } else if (max === g) {
        h = 60 * (((b - r) / delta) + 2);
    } else if (max === b) {
        h = 60 * (((r - g) / delta) + 4);
    }

    // Calculate Saturation (S)
    s = max === 0 ? 0 : (delta / max);

    // Calculate Value (V)
    v = max;

    return `${Math.round(h)}°, ${Math.round(s * 100)}%, ${Math.round(v * 100)}%`;
}


export const hexToRGB = (hexColor) => {
    hexColor = hexColor.replace('#', '');
    const numericValue = parseInt(hexColor, 16);
    const r = numericValue >> 16 & 0xFF;
    const g = numericValue >> 8 & 0xFF;
    const b = numericValue & 0xFF;
    return `${r}, ${g}, ${b}`
  }


export function hexToUIColorSwift(hexColor) {
    // Remove the '#' if present
    hexColor = hexColor.replace('#', '');

    // Parse the hex values to RGB
    const r = parseInt(hexColor.substring(0, 2), 16) / 255;
    const g = parseInt(hexColor.substring(2, 4), 16) / 255;
    const b = parseInt(hexColor.substring(4, 6), 16) / 255;

    if (!r || !g || !b) { return null }

    // Construct the Swift UIColor string
    const swiftUIColorString = `UIColor(red: ${r.toFixed(2)}, green: ${g.toFixed(2)}, blue: ${b.toFixed(2)}, alpha: 1.0)`;

    return swiftUIColorString;
}

export function hexToUIColorObjC(hexColor) {
    // Remove the '#' if present
    hexColor = hexColor.replace('#', '');

    // Parse the hex values to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    if (!r || !g || !b) { return null }

    // Construct the Swift UIColor string
    const swiftUIColorString = `[UIColor colorWithRed: ${r}/255 green: ${g}/255 blue: ${b}/255 alpha: 1.0]`;
   
    return swiftUIColorString;
}

export function hexToCSS(hexColor) {
    return `.color { background-color: ${hexColor}; }`;
}

export function getRandomHexColor() {
    // Generate a random number between 0 and 16777215 (hexadecimal color range)
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    
    // Ensure the color code is always six digits
    return '#' + '0'.repeat(6 - randomColor.length) + randomColor;
  }
  

export function hexToHSL(hex) {
    // Remove the '#' if present
    hex = hex.replace(/^#/, '');

    // Parse the R, G, B values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    // Convert RGB to HSL
    r /= 255;
    g /= 255;
    b /= 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    // Scale HSL values to [0, 1] and format as a string
    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    return `${h}, ${s}%, ${l}%`;
}



export function convertHexToAndroidColor(hexColor) {
    // Remove the '#' if it exists
    hexColor = hexColor.replace(/^#/, '');

    // Parse the hex color to integer
    const hexValue = parseInt(hexColor, 16);

    if (!hexValue) { return null }

    // Create the Android color variable string
    const androidColorString = `Color.valueOf(0x${hexValue.toString(16).padStart(8, '0')})`;

    return androidColorString;
}

export function rgbToHex(rgbColor) {
    // Check if the input string matches the expected format
    const regex = /^\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*$/;
    const match = rgbColor.match(regex);

    if (match) {
        const red = Math.floor(parseInt(match[1]));
        const green = Math.floor(parseInt(match[2]));
        const blue = Math.floor(parseInt(match[3]));

        // Check if the values are within the valid range (0-255)
        if (red >= 0 && red <= 255 && green >= 0 && green <= 255 && blue >= 0 && blue <= 255) {
            // Convert RGB to HEX
            const hex = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
            return hex.toUpperCase(); // Convert to uppercase for consistency
        }
    }

    // Return null if the input format is incorrect
    return null;
}

export function rgbToHsl(rgbColor) {
    // Check if the input string matches the expected format
    const regex = /^\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*$/;
    const match = rgbColor.match(regex);

    if (match) {
        const red = Math.min(255, Math.max(0, parseInt(match[1])));
        const green = Math.min(255, Math.max(0, parseInt(match[2])));
        const blue = Math.min(255, Math.max(0, parseInt(match[3])));

        const r = red / 255;
        const g = green / 255;
        const b = blue / 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }

            h /= 6;
        }

        h = Math.round(h * 360);
        s = Math.round(s * 100);
        l = Math.round(l * 100);

        return `${h}, ${s}%, ${l}%`;
    }

    return null;
}

export function rgbToCmyk(rgbColor) {
    // Check if the input string matches the expected format
    const regex = /^\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*$/;
    const match = rgbColor.match(regex);

    if (match) {
        const red = Math.min(255, Math.max(0, parseInt(match[1])));
        const green = Math.min(255, Math.max(0, parseInt(match[2])));
        const blue = Math.min(255, Math.max(0, parseInt(match[3])));

        const r = red / 255;
        const g = green / 255;
        const b = blue / 255;

        const k = 1 - Math.max(r, g, b);
        const c = (1 - r - k) / (1 - k);
        const m = (1 - g - k) / (1 - k);
        const y = (1 - b - k) / (1 - k);

        return `${Math.round(c * 100)}, ${Math.round(m * 100)}, ${Math.round(y * 100)}, ${Math.round(k * 100)}`;
    }

    return null;
}



export function rgbToUIColor(rgbColor) {
    // Check if the input string matches the expected format
    const regex = /^\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*$/;
    const match = rgbColor.match(regex);

    if (match) {
        const red = Math.floor(parseInt(match[1]));
        const green = Math.floor(parseInt(match[2]));
        const blue = Math.floor(parseInt(match[3]));

        // Check if the values are within the valid range (0-255)
        if (red >= 0 && red <= 255 && green >= 0 && green <= 255 && blue >= 0 && blue <= 255) {
            // Convert the RGB values to Swift UIColor format
            const swiftColorString = `UIColor(red: ${red/255}, green: ${green/255}, blue: ${blue/255}, alpha: 1.0)`;
            return swiftColorString;
        }
    }

    // Return null if the input format is incorrect
    return null;
}

export function rgbToJava(rgbColor) {
    // Check if the input string matches the expected format
    const regex = /^\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*$/;
    const match = rgbColor.match(regex);

    if (match) {
        const red = Math.floor(parseInt(match[1]));
        const green = Math.floor(parseInt(match[2]));
        const blue = Math.floor(parseInt(match[3]));

        // Check if the values are within the valid range (0-255)
        if (red >= 0 && red <= 255 && green >= 0 && green <= 255 && blue >= 0 && blue <= 255) {
            // Convert RGB to Java Color.valueOf()
            const javaColor = `Color.valueOf(0x${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')})`;
            return javaColor;
        }
    }

    // Return null if the input format is incorrect
    return null;
}
