import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import RandomColorPalette, { Color } from "./Pages/ColorPalette/RandomColorPalette";
import HexToConvert from "./Components/Convert/HexToConvert";
import RgbToConvert from "./Components/Convert/RgbToConvert";
import HexToTech from "./Components/Convert/HexToTech";
import RgbToTech from "./Components/Convert/RgbToTech";
import ColorNames from "./Pages/ColorNames/ColorNames";
import NotFound from "./Pages/404/NotFound";
import { getWebsiteName } from "./Helpers/HelperFunctions";
import { META_TITLE_ADDITION } from "./Constants/Constants";

export const DEFAULT_META_DESCRIPTION = "View and expand your HTML or CSS color codes! Discover new colors for your project and convert color codes into any related color values. Convert HEX to RGB, RGB to CYMK and more! Explore the web safe colors and pre-made color palettes for your project"
export const DEFAULT_META_KEYWORDS = "color, colour, color code, hex color, rgb color, color chart, color wheel, design, web design"
export const COLOR_META_TAG = (color) => { 
  return ("color, colour, color code, hex color, rgb color, color chart, color wheel, design, web design") 
}
export const DEFAULT_META_TITLE = 'Home - ' + getWebsiteName + META_TITLE_ADDITION
export const TITLE_META_TAG = (title) => {
  return (`${title} - ${getWebsiteName}`)
}

function App() {
  return (  
    <>
      <Helmet>
        <title>{DEFAULT_META_TITLE}</title>
        <meta
          name="description"
          content={DEFAULT_META_DESCRIPTION}
        />
        <meta
          name="keywords"
          content={DEFAULT_META_KEYWORDS}
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/color-names" element={<ColorNames />} />
        <Route path="/color-palette/random" element={<RandomColorPalette displayFirstPalette={true} />} />
        <Route path="/color-palette/:name" element={<RandomColorPalette displayFirstPalette={true} />} />
        <Route path="/color-palettes" element={<RandomColorPalette displayFirstPalette={false} />} />
        <Route path="/hex/:code" element={<About />} />
        <Route path="/hex-to/:code" element={<HexToConvert />} />
        <Route path="/rgb-to/:code" element={<RgbToConvert />} />
        <Route path="/hex-to/uicolor" element={<HexToTech code={"UIColor"} />} />
        <Route path="/hex-to/java" element={<HexToTech code={"Java"} />} />
        <Route path="/rgb-to/uicolor" element={<RgbToTech code={"UIColor"} />} />
        <Route path="/rgb-to/java" element={<RgbToTech code={"Java"} />} />
      </Routes>
      </>
  );
}

export default App;
