import { useParams } from "react-router";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import { Content } from "../../Pages/Home/Home";
import Convert from "../../Pages/Convert/Convert";
import { getWebsiteName } from "../../Helpers/HelperFunctions";

export default function HexToConvert() {
    const {code} = useParams();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/hex-to/${code}` });
    })

    var index = 0;
    switch(code.toUpperCase()) {
        case "RGB":
            index = 0
            break
        case "HSL":
            index = 1
            break
        case "CYMK":
            index = 2
            break
        default:
            break
    }

    return(
        <>
        <div style={{backgroundColor: '#F0F0F0'}}>
            <Content style={{backgroundColor: 'white', borderWidth: '0.1px', borderColor: 'gray', borderStyle: 'none solid'}}>
                <div style={{margin: 'auto'}}>
                    <Convert title={code.toUpperCase()} index={index} original={"HEX"}/>
                </div>
            </Content>
        </div>
        </>
    );
}